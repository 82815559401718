import React from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import classnames from 'classnames';

import { DatagridRow } from 'react-admin';

const DatagridBody = ({
  basePath,
  children,
  classes,
  className,
  data,
  expand,
  ids,
  resource,
  row,
  rowStyle,
  selectedIds,
  styles,
  version,
  ...rest
}) => (
  <TableBody className={classnames('datagrid-body', className)} {...rest}>
    {ids.map((id, rowIndex) =>
      React.cloneElement(
        row,
        {
          basePath,
          classes,
          className: classnames(classes.row, {
            [classes.rowEven]: rowIndex % 2 === 0,
            [classes.rowOdd]: rowIndex % 2 !== 0,
          }),
          expand,
          id,
          key: id,
          record: data[id],
          resource,
          style: rowStyle ? rowStyle(data[id], rowIndex) : null,
        },
        children
      )
    )}
  </TableBody>
);

DatagridBody.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  data: PropTypes.object.isRequired,
  expand: PropTypes.node,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  resource: PropTypes.string,
  row: PropTypes.element.isRequired,
  rowStyle: PropTypes.func,
  styles: PropTypes.object,
  version: PropTypes.number,
};

DatagridBody.defaultProps = {
  data: {},
  ids: [],
  row: <DatagridRow />,
};

export default DatagridBody;