import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress, List  } from '@material-ui/core';
import { 
  GET_LIST,
  fetchEnd,
  fetchStart,
  translate
} from 'react-admin';
import compose from 'recompose/compose';

import dataProvider from '../../providers/dataProvider';
import CampaignListItem from './CampaignListItem';
import { SimpleModal } from '../../components';

const styles = {
  content: { padding: 0 },
  titleDiv:{
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton:{
    marginTop: '0.5em',
    marginRight: '0.5em'
  }
};

class CampaignDialog extends Component {
  state = {
    campaigns: []
  }

  handleEntering = () => {
    const {fetchStart, fetchEnd} = this.props;
    fetchStart();
    dataProvider(GET_LIST, 'campaigns', {
      filter: { hide: false },
      sort: { field: 'beginDate', order: 'DESC' },
      pagination: { page: 1, perPage: 0 },
    }).then(response => {
      return response.data;
    }).then(campaigns =>  {
      fetchEnd(); 
      this.setState({'campaigns': campaigns})
    });
  };

  render() {
    const { translate, onClose, open } = this.props
    const { campaigns } = this.state

    return <SimpleModal open={open} onClose={onClose} onEntering={this.handleEntering} 
    title={translate('resources.campaigns.name', {smart_count: 2})} scroll='body' maxWidth='md'>
      {
        campaigns.length > 0 ?
        <List>
          {campaigns.map(campaign => (
            <CampaignListItem campaign={campaign} key={campaign.id} onClose={onClose} />
          ))}
        </List>
        : <LinearProgress/>
      }
    </SimpleModal>
  }
}

CampaignDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    fetchStart,
    fetchEnd
  })
);

export default enhance(CampaignDialog);