import React from 'react';

import {
  CheckCircle as CheckCircleIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  ErrorOutline as ErrorOutlineIcon,
  OfflinePin as OfflinePinIcon
} from '@material-ui/icons';
import {
  Tooltip,
  // IconButton 
} from '@material-ui/core';
const Status = ({source, record, className, ...props}) => {

  const status = record[source];

  switch(status) {
    case 'UPDATED': 
      return (
        <Tooltip title={'Updated'}>
          <CheckCircleIcon style={{ color: 'rgba(50,205,50)' }}/>
        </Tooltip>
      )

    case 'PROCESSING': 
    case 'PENDING': 
      return (
        <Tooltip title={'Processing'}>
          <RadioButtonUncheckedIcon style={{ color: 'rgba(0,89,255)' }}/>
        </Tooltip>
      )
    
    case 'CREATED': 
      return (
        <Tooltip title={'Created'}>
          <RadioButtonUncheckedIcon style={{ color: 'rgba(255,165,0)' }}/>
        </Tooltip>
      )

    case 'OUTDATED': 
    return (
      <Tooltip title={'Outdated'}>
        <OfflinePinIcon style={{ color: 'rgba(255,165,0)' }}/>
      </Tooltip>
    )
    default: 
      return (
        <Tooltip title={'Error'}>
          <ErrorOutlineIcon style={{ color: 'rgba(255,0,0)' }}/>
        </Tooltip>
      )
  }
}

Status.defaultProps = {
  addLabel: true,
};

export default Status;