import englishMessages from 'ra-language-english';

export default {
  ...englishMessages,
  errors: {
    SOMETHING: 'An error has occurred',
    LOCALSTORAGE_FULL: 'LocalStorage is full, please empty data',
    '404': 'Can\'t find answer to request',
    '405': 'Your institution doesn\'t own the model',
    '500': 'Some error occured',
    '422': 'Error validating fields on server',
    ALREADY_EXISTS: 'Resource already exists',
    AUTHORIZATION_REQUIRED: 'Authorization required to access page',
    LOGIN_FAILED: 'Login failed',
    INSTITUTION_MISSING: 'Institution Missing',
  },
  sockets: {
    build_success: 'Launch %{launchName} updated',
    build_fail: 'Some error occur with launch %{launchName}',
    wave_parameters_success: 'Wave parameters %{waveParamId} calculated',
    wave_parameters_fail: 'Some error occur with wave parameters %{waveParamId}',
    file_process_success: 'File %{name} processed',
    file_process_duplicated: 'File %{name} duplicated',
    file_process_fail: 'File %{name} failed',
    update_data: 'Updated data'
  },
  dates: {
    not_defined: 'Date not defined'
  },
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: {
      name: 'Language',
      'en-GB': 'English',
      pt: 'Português',
    },
    manual: 'Manual',
    servicedesk: 'Service Desk',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    update: {
      name: 'Realtime Updates',
      yes: 'Yes',
      no: 'No',
    },
    submenu: {
      datasets: 'Datasets',
      equipment: 'Equipment'
    },
    dashboard: {
      campaign_count: 'Available Campaign',
      last_campaign: 'Most recent Campaign',
      welcome: {
        title: 'WAVY Operation Software',
        subtitle: "Welcome to the WAVY Operation Software. Feel free to explore this software and submit your suggestions for improvement.",
      },
    },
    profile: {
      profile: 'Profile',
      profile_updated: 'Profile updated',
      profile_update_error: 'Error updating your profile',
      old_password: 'Current password',
      new_password: 'New password',
      repeat_password: 'Repeat password',
      change_password: 'Change password',
      different_passwords: 'Passwords not matching',
      password_changed: 'Password changed successfully'
    },
    general: {
      add_referencing: 'Add %{reference}',
      remove_referencing: 'Remove %{reference}',
      add_button: 'Add',
      remove_button: 'Remove',
      select_all: 'Select All',
      unselect_all: 'Unselect All',
      map: 'Map',
      catalogue: 'Catalogue',
      publish: 'Publish',
      delete: 'Delete',
      re_publish: 'Re-publish',
      publish_failed: 'Publish failed',
      publish_success: 'Publish completed successfully',
      delete_success: 'Publish deleted',
      delete_failed: 'Publish failed to delete',
      table: 'Table',
      chart: 'Chart',
      update: 'Update',
      open: 'Open',
      closed: 'Closed',
      active: 'Ative',
      inactive: 'Inactive',
      in_use: 'In use',
      available: 'Available',
      edit: 'Edit',
      list: 'List',
      export: 'Export'
    },
    derived: {
      to_dts: 'to Curated Dataset',
      searchLabel: 'Name'
    },
    info: {
      one_selectable: 'This button has no function, only one record is selectable.'
    },
    annotation: {
      clicked: 'Click again on the chart to add an annotation.',
      created: 'Annotation created',
      loading: 'Loading data'
    }
  },
  containers: {
    annotations: {
      modalTitle: "Annotation",
      modalEdit: "Edit annotation",
      next: "NEXT",
      begin: "BeginTime",
      time: "Timestamp",
      record: "Record",
      tags: "Tags",
      description: 'Description',
      end: "EndTime",
      choose: "Choose the %{hour} time",
      need_be_bigger: "Select a time after Begin Time!",
      observations: "observations",
      tag_undefined: "This annotation has no tags",
      show_all: "Anotations"
    },
    parameters: {
      modalTitle: "Calculate parameters",
      next: "NEXT",
      begin: "Begin",
      end: "End",
      choose: "Choose the %{hour} time",
      need_be_bigger: "Select a time after Begin Time!",
      observations: "observations",
      success: "Started calculating!",
      observationsNumber: "Observations Number",
      timeLimit: "Time interval",
      timeUnit: "Minutes",
    },
    maps: {
      history: {
        name: 'History Map',
        complete_interval: 'Complete interval',
        all_data: 'All data',
        info: {
          no_data: 'There is no data available!'
        }
      },
      live: {
        name: 'Live Map',
      },
      datasets: {
        name: 'Datasets Map'
      }
    },
    search: {
      name: 'Search',
      fields: {
        equipment: 'Equipment',
        beginTimestamp: 'Begin timestamp',
        endTimestamp: 'End timestamp'
      },
      errors: {
        dates: 'Invalid time interval, begin timestamp more recent than end timestamp.'
      }
    },
    derivedsets: {
      map: {
        name: 'Curated Datasets Map',
        loadObsError: 'Error loading observations'
      },
      chart: {
        name: 'Curated Datasets Chart'
      }
    },
    charts: {
      name: 'Charts',
      unloadable: 'It wasn\'t possible to load the graph. Might have no data.'
    },
    properties: {
      altitude: 'Altitude',
      battery: 'Battery',
      date: 'Date',
      hdop: 'Hdop',
      latitude: 'Latitude',
      longitude: 'Longitude',
      no_data: 'No Data',
      rarc: 'RARC',
      satellites: 'Satellites',
      speed: 'Speed',
      timestamp: 'Timestamp',
      rssi: 'rssi'
    },
    manual: {
      addSection: 'Add Section',
      addSubSection: 'Add subsection',
      title: '%{action} %{type}',
      fields: {
        title: 'Title',
        section: 'Section',
        subsection: 'Subsection',
      },
      error: {
        invalid_number: 'It must be a positive number bigger than 0'
      }
    }
  },
  inputs: {
    coordinates: {
      latitude: 'Latitude',
      longitude: 'Longitude',
    },
    error: {
      invalid_latitude: 'Latitude must be a value between -90 and 90',
      invalid_longitude: 'Longitude must be a value between -180 and 180'
    },
    general: {
      update: 'Element updated',
      create: 'Element created',
      delete: 'Element deleted',
    }
  },
  error: {
    INVALID_PASSWORD: 'The current password is wrong',
    CAMPAIGN_OPEN_LAUNCH: 'The campaign already has an open launch',
    CAMPAIGN_NOT_FOUND: 'The campaign could not be found',
    UNAUTHORIZED: 'Unauthorized',
    USER_IS_SYSADMIN: 'The user is the system administrator',
    USER_NOT_FOUND: 'User could not be found',
    LAUNCH_NOT_FOUND: 'Launch could not be found',
    BEGIN_TIMESTAMP_LATER: 'Begin timestamp must be earlier than end timestamp',
    END_TIMESTAMP_EARLIER: 'End timestamp must be later than begin timestamp',
    BUILDING_ERROR: 'Error building the launch'
  },
  resources: {
    campaigns: {
      name: 'Campaign |||| Campaigns',
      fields: {
        name: 'Name',
        description: 'Description',
        beginDate: 'Begin Date',
        area: 'Location',
        zoom: 'Zoom',
        hide: 'Hide',
        noEquipments: 'No equipment is yet associated to this campaign!',
        owner: 'Owner'
      },
      tabs: {
        details: "Details",
        launches: "Launches",
        datasets: "Dataset",
        equipment: "Equipment",
        teams: "Teams",
        overlays: "Overlays",
        notes: "Notes",
        satellites: 'Satellites'
      },
      satellites: {
        calculate_button: 'Calculate',
        charts_color: 'Charts color',
        begin_date: 'Begin Date',
        end_date: 'End Date',
        color0: 'Blue',
        color1: 'Grey',
        color2: 'Orange',
        chart1_xAxis_title: 'Hours p/Day',
        chart1_yAxis_avereage_title: 'Average',
        chart1_yAxis_probability_title: 'Coverage',
        chart1_avereageSeries_name: 'satellites average',
        chart1_probabilitySeries_name: 'Coverage',
        chart2_title: 'Total Satellites p/Period',
        minimum_elevation: 'Minimum Elevation',
        request_error: 'Some error occurred with the satellites request'
      }
    },
    datasets: {
      name: 'Dataset |||| Datasets',
      fields: {
        name: 'Name',
        equipmentId: 'Equipment',
        launchId: 'Launch',
        campaignId: 'Campaign',
        numberObservations: 'Number of observations',
      },
      copyNotification: 'Curated dataset will be processed soon',
      copyButton: 'Clone',
      createDerivedset: 'Create a Curated Dataset',
      buildJob: 'You want update %{datasetName}?',
      buildJobWarning: 'The actual data will be erased!',
      buildJobNotification: '%{datasetName} will be updated soon',
      download_error: 'File not found'
    },
    equipment: {
      name: 'Equipment |||| Equipment',
      allObservations: 'All observations',
      fields: {
        state: "State",
        name: 'Name',
        serialNumber: 'Serial Number',
        number: 'Number',
        equipmentModelId: 'Model',
        import: 'Imported',
        identifier: 'Argos Id',
        properties: {
          color: 'Color'
        },
      },
      removed_eq: 'Removed the selected equipments',
      dataset_published: 'The selected equipments has a published dataset',
      recovery: {
        button_label: 'Recovery Mode',
        confirm_button_label_activated: 'Recovery mode is ON',
        confirm_button_label_deactivated: 'Recovery mode is OFF',
        confirm_button_label_activating: 'Activating Recovery mode',
        confirm_button_label_deactivating: 'Deactivating Recovery mode',
        confirm_text_active: 'Are you sure you want to active the recovery mode for this equipment?',
        confirm_text_deactivate: 'Are you sure you want to deactivate the recovery mode for this equipment?',
        success_notification: 'Recovery message has been sent to recover equipment',
        failure_notification: 'Error getting the WAVY Ocean mode'
        // message_notification: 'Recovery message has already been sent'
      },
      agreement: {
        success: 'Terms of Service accepted',
        failure: 'Some error occurred accepting the Terms of Service',
        agreement: 'Terms of Service',
        to_accept: 'Accept the ',
        accepted: ' already accepted',
      },
      tabs: {
        details: 'Details',
        datasets: 'Datasets',
        campaigns: 'Campaigns',
        calibrations: 'Calibrations'
      },
      calibrations: {
        calibrationDate: 'Calibration Date',
        validDate: 'Validation Date',
        sensorType: 'Sensor name',
        onEquipment: 'On equipment',
        numVariables: 'Polynomial',
        polynomialDegree1: 'Linear',
        polynomialDegree2: 'Cubic',
        diffValidationDate: 'Diferent Validation Date',
        sensor_1: 'temp_1',
        sensor_2: 'temp_2',
        none: 'None',
        filter: 'Filter',
        formula: 'Sensor Value',
      }
    },
    institutions: {
      name: 'Institution |||| Institutions',
      fields: {
        name: 'Name',
        address: 'Address',
        phoneNumber: 'Phone Number',
        acronym: "Acronym",
        url: "Internet Site"
      },
      tabs: {
        details: 'Details',
        users: 'Members'
      }
    },
    observations: {
      name: 'Observation |||| Observations',
      fields: {
        number: 'Number',
        timestamp: 'Time UTC',
        'position.lat': 'Latitude',
        'position.lng': 'Longitude',
        battery: 'Battery',
        speed: 'Speed',
        direction: 'Direction',
        copying: 'Copying'
      },
      button: {
        csv_export: 'CSV',
        metadata_export: 'Metadata'
      }
    },
    teams: {
      name: 'Team |||| Teams',
      fields: {
        name: 'Name',
        code: 'Code',
        description: "Description"
      },
      tabs: {
        details: 'Details',
        users: 'Members'
      }
    },
    users: {
      name: 'User |||| Users',
      fields: {
        username: 'Username',
        name: 'Name',
        email: 'E-mail',
        institutionId: 'Institution',
        active: 'Active',
        isAdmin: 'Administrator',
        role: 'Administrator'
      }
    },
    annotations: {
      name: 'Annotation |||| Annotations',
      fields: {
        observationIds: 'Observations Ids',
        title: 'Title',
        text: 'Text',
        tagIds: 'Tags'
      },
      messages: {
        updateError: 'Some error occurred when updating the annotation',
        dateGt: 'The date needs to be greater than %{date}',
        dateLt: 'The date needs to be lower than %{date}',
        getDatesError: 'Some error occurred getting the limit dates',
        getAnnotations: 'Some error occurred getting annotations',
        createAnnotations: 'Some error occurred when creating the annotation',
      },
    },
    notes: {
      name: 'Note |||| Notes',
      title: 'Notes',
      fields: {
        beginDate: 'Date',
        description: 'Description',
        tagIds: 'Tags',
        campaign: 'Campaign'
      },
      campaignGetError: 'Error getting campaigns data',
      success: 'Note created',
      tabs: {
        details: 'Details',
        description: 'Description',
      }
    },
    launches: {
      name: 'Launch |||| Launches',
      fields: {
        state: "State",
        name: 'Name',
        description: 'Description',
        beginTimestamp: 'Begin Date',
        endTimestamp: 'End Date',
        autoPublish: 'Auto Publish',
        equipmentAdded: 'Equipments'
      },
      buttons: {
        create: 'Create',
        terminate: 'Finish Launch',
        terminateAll: 'Close All',
        build: 'Build'
      },
      message: {
        build: 'Start building'
      },
      tabs: {
        details: 'Details',
        equipment: 'Equipment'
      }
    },
    overlays: {
      name: 'Overlay |||| Overlays',
      fields: {
        name: 'Name',
        opacity: 'Opacity',
        filePath: 'Url',
        type: 'Type',
        layers: 'Layers',
        image: 'Image',
        wms: 'WMS',
        kml: 'KML',
        southWest: 'Southwest point',
        northEast: 'Northeast point'
      },
      file: 'File',
      url: 'url'
    },
    files: {
      name: 'File |||| Files',
      fields: {
        name: 'Name',
        originalFilename: 'Original File Name',
        identifier: 'Identifier',
        createdAt: 'Upload Date',
        number: 'Number',
        altitude: 'Altitude',
        speed: 'Speed',
        position: 'Position',
        direction: 'Direction',
        satellites: 'Satellites',
        hdop: 'Hdop',
        timestamp: 'Time',
        file: 'File',
        uploadFile: 'Upload File'
      },
      filter: {
        success: 'Success',
        duplicated: 'Duplicated',
        pending: 'Pending',
        error1: 'Error',
        error2: 'Error on columns length',
        error3: 'Error with dates',
      }
    },
    derivedsets: {
      name: 'CuratedSet |||| Curated',
      fields: {
        name: 'Name',
        description: 'Description',
        removed_noti: 'Removed %{id} elements',
        remove: 'Remove',
        numberObservations: 'Number of observations',
      },
      notification:{
        update_metadata: 'The metadata and CSV has been updated'
      },
      forms: {
        buttons: {
          tags_filter: 'Tag filter',
          delete_observations: "Delete Content",
          delete_observations_confirm_title: "Delete Content",
          delete_observations_confirm_description: "You want delete all observation from this curatedSet?",
          delete_observations_confirm_notification: "Deleted all observations",
        }
      }
    },
    equipmenttypes: {
      name: 'Type |||| Types',
      fields: {
        name: 'Name'
      }
    },
    equipmentmodels: {
      name: 'Model |||| Models',
      fields: {
        name: "Name",
        equipmenttypeId: "Equipment Type",
        observedProperty: 'Properties',
        properties: {
          visible: 'Visible',
          observedpropertyId: 'Observed Property',
          unitOfMeasurement: 'Unit of Measurement',
          index: 'Index'
        }
      },
      warning: {
        removeProperty: "Can't be removed"
      },
      errors: {
        index_lt1: 'Index must be a value greater than 0. ',
        duplicate_index: "Can't have duplicated Indexes. ",
        related_properties: "Error saving properties.",
      }
    },
    observedProperties: {
      name: 'Property |||| Properties',
      fields: {
        name: 'Name',
        description: 'Description'
      }
    },
    tags: {
      name: 'Tag |||| Tags',
      tabs: {
        details: 'Details',
      },
      fields: {
        properties: {
          color: 'Color',
        },
      }
    },
    parameters: {
      name: 'Parameter |||| Parameters',
      title: 'Parameters',
      titleCurated: '(curated dataset)',
      tabs: {
        details: 'Details',
      },
      fields: {
        properties: {
          color: 'Color',
        },
      },
      equipment: 'Equipment',
      derived: "Curated",
      calculate: "Calculate",
      download: "Download CSV",
      error: "Insufficient data select. Please select a higher amount of data.",
      fileError: "File not found.",
    }
  }
};
