import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
// import inflection from 'inflection';
import { translate/*, crudDelete*/ } from 'ra-core';

import { Button, refreshView, showNotification } from 'react-admin';
import dataFetch from '../../../providers/dataFetch';
import { push } from 'react-router-redux';
import { Build } from '@material-ui/icons'

const sanitizeRestProps = ({
    basePath,
    classes,
    crudDelete,
    filterValues,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    label,
    pristine,
    resource,
    saving,
    selectedIds,
    submitOnEnter,
    redirect,
    refreshView,
    showNotification,
    push,
    ...rest
}) => rest;

const styles = theme =>{
    return createStyles({
        deleteButton: {
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.primary.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });
}

class CalculateContentButton extends Component {
    state = { disabled: false };

    handleClick = e => {
        // TODO: 
        // e.stopPropagation();
        dataFetch('POST', `/parameters/calculate/${this.props.id}`)
        .then(_ => {
            this.props.showNotification('containers.parameters.success');
            this.setState({disabled: true});
        })
        .catch(err => {
            if(err && err.error && err.error.code === "PROCESS_IN_LIST"){
                this.props.showNotification('resources.parameters.fileError', 'warning');
            } else if(err && err.error && err.error.message){
                console.error(err.error.message);
                this.props.showNotification('errors.SOMETHING', 'warning');
            } else{
                this.props.showNotification('errors.SOMETHING', 'warning');
            }
        });
    };

    render() {
        const {
            classes = {},
            className,
            icon,
            label,
            c_title,
            c_content,
            onClick,
            record,
            resource,
            translate,
            ...rest
        } = this.props;
        return (
            <Fragment>
                <Button
                    disabled={this.state.disabled}
                    onClick={this.handleClick}
                    label={label ? label : translate('resources.parameters.calculate')}
                    className={classnames(
                        'ra-delete-button',
                        classes.deleteButton,
                        className
                    )}
                    color={'primary'}

                    key="button"
                    {...sanitizeRestProps(rest)}
                >
                    <Build />
                </Button>
            </Fragment>
        );
    }
}

CalculateContentButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    translate: PropTypes.func,
    icon: PropTypes.element,
};

CalculateContentButton.defaultProps = {
    redirect: 'list',
    icon: <ActionDelete />,
};

export default compose(
  connect(null, {
    refreshView,
    showNotification,
    push   
  }),
  translate,
  withStyles(styles)
)(CalculateContentButton);