import React, { Component } from 'react';
import {
  Button,
  ListButton,
} from 'react-admin';

import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { dataFetch } from '../../providers';

import { translate} from 'ra-core';
import { parse } from 'query-string';
import Status from './Status'


//import ParamCardActions from './ParamCardActions';
import RunButton from './Show/RunButton';
import {
  GetApp as DownloadIcon
} from '@material-ui/icons';
import fetchFile from '../../providers/fetchFile';
import {
  SimpleForm,
  TextInput,
  crudGetOne,
  DateField,
  EditButton
} from 'react-admin';

import {
  Edit,
} from '../../components';


const redirect = (basePath, id, data) => {
  return `/parameters/${id}/show`;
}

class EditActionsClass extends Component {
  state={}

  render() {
    return <div style={{display:'flex', justifyContent:'space-between'}}>
      <Typography variant='title'>{this.props.campaign && this.props.campaign.name}</Typography>
      {this.props.data && <ListButton to={`/parameters`}/>}
    </div>
  }
}


const EditActions = connect(null, { crudGetOne })(EditActionsClass);

const CustomSimpleForm = ({record, redirect, ...props}) => (
  <SimpleForm {...props} record={record} redirect={`/parameters/${record.id}/show`}>
    {props.children}
  </SimpleForm>
);

class ParameterEditView extends Component {

  render() {
    const { campaign, translate, ...props } = this.props;
    return <Edit actions={<EditActions />} {...props} >
      <CustomSimpleForm redirect={redirect}    >
        <TextInput multiline source='description' fullWidth={true} />
        <Status source="status" />
        <DateField key='datetime' source="beginTime" showTime />
        <DateField key='datetime' source="endTime" showTime />
      </CustomSimpleForm>
    </Edit>
  }
}



class ShowActionsClass extends Component {

  state = { title: '', csvDisabled: false }
  componentDidMount() {
    if (this.props.data && this.props.data.derivedsetId) {
      const filter = {
        where: { id: this.props.data.derivedsetId }
      };

      dataFetch('GET', `/derivedsets?filter=${JSON.stringify(filter)}`)
        .then(result => {
          this.setState({ title: result[0].name });
        })
        .catch(err => { });
    }
  }
  

    exportCSV = () => {
      const { id } = this.props;
      const fileName = `parameter_${id}.csv`;
      // window.open(fetchFile(`/datasets/csv/${id}`), '_blank');
      window.open(fetchFile(`/containers/parameters/download/${fileName}`), '_black');
    }
    // this.setState({csvDisabled: true});
  
    render() {
      const { id, translate,data } = this.props;
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='title'>{this.state.title} {translate('resources.parameters.titleCurated')}</Typography>
  
          <span style={{ display: 'flex', justifyContent: 'space-between'  }}>
            <Typography variant='caption'> </Typography>
            <RunButton id={id}/>
            <ListButton basePath='/parameters' /*label='resources.parameters.title'*//>
            <EditButton basePath='/parameters' record={data ? data : {id: id}} />
            <Button disabled={this.state.csvDisabled} label='pos.general.export' onClick={() => this.exportCSV()}><DownloadIcon/></Button>
          </span>
        </div>
      )
    }
  }
  const mapStateToProps = (state, props) => {
    const search = parse(props.location.search, { arrayFormat: 'bracket' })
    return {
      campaign: state.admin.resources.parameters.data[search.id]
    }
  }
  const enhanceCreateEdit = compose(
    translate,
    connect(mapStateToProps, {
      crudGetOne
    })
  )
export const ParametersEdit = enhanceCreateEdit(ParameterEditView);

  export default compose(
    connect(null, {

    }),
    translate,
  )(ShowActionsClass);