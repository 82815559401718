import React, { Component, isValidElement, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { sanitizeListRestProps } from 'ra-core';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import classnames from 'classnames';

import DatagridHeaderCell from './DatagridHeaderCell';
import DatagridBody from './DatagridBody';

const styles = theme => createStyles({
  table: {
    tableLayout: 'auto',
  },
  thead: {},
  tbody: {
    height: 'inherit',
  },
  headerRow: {},
  headerCell: {
    padding: '0 12px',
    '&:last-child': {
      padding: '0 12px',
    },
  },
  checkbox: {},
  row: {},
  clickableRow: {
    cursor: 'pointer',
  },
  rowEven: {},
  rowOdd: {},
  rowCell: {
    padding: '0 12px',
    '&:last-child': {
      padding: '0 12px',
    },
  },
  expandHeader: {
    padding: 0,
    width: 48,
  },
  expandIconCell: {
    width: 48,
  },
  expandIcon: {
    transform: 'rotate(-90deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expanded: {
    transform: 'rotate(0deg)',
  },
});

class Datagrid extends Component {

  render() {
    const {
      basePath,
      body,
      children,
      classes,
      className,
      expand,
      ids,
      resource,
      rowStyle,
      selectedIds,
      total,
      version,
      choices,
      ...rest
    } = this.props;

    return (
      <Table
        className={classnames(classes.table, className)}
        {...sanitizeListRestProps(rest)}
      >
        <TableHead className={classes.thead}>
          <TableRow
            className={classnames(classes.row, classes.headerRow)}
          >
            {expand && (
              <TableCell className={classes.expandHeader} />
            )}

            {Children.map(children, (field, index) =>
              isValidElement(field) ? (
                <DatagridHeaderCell
                  className={classes.headerCell}
                  field={field}
                  key={field.props.source || index}
                  resource={resource}
                />
              ) : null
            )}
          </TableRow>
        </TableHead>
        {cloneElement(
          body,
          {
            basePath,
            className: classes.tbody,
            classes,
            expand,
            data: choices,
            ids,
            resource,
            rowStyle,
            selectedIds,
            version,
          },
          children
        )}

      </Table>
    );
  }
}

Datagrid.propTypes = {
  basePath: PropTypes.string,
  body: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  expand: PropTypes.node,
  hover: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.any).isRequired,
  onToggleItem: PropTypes.func,
  resource: PropTypes.string,
  rowStyle: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  total: PropTypes.number,
  version: PropTypes.number,
};

Datagrid.defaultProps = {
  data: {},
  ids: [],
  selectedIds: [],
  body: <DatagridBody />,
};

export default withStyles(styles)(Datagrid);