import React, { Component } from 'react';
import { ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import { 
  GET_LIST, 
  // DateField,
  fetchEnd,
  fetchStart,
  showNotification,
  translate,
} from 'react-admin';
import {
  dataFetch,
  dataProvider
} from '../../providers';
import {
  DateField
} from '../../components';

import { connect } from 'react-redux';
import { 
  fetchObservations as fetchObservationsAction,
  selectCampaign as selectCampaignAction, 
  storeOverlays as storeOverlaysAction,
} from './actions';

import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  date: { display: 'inline' },
  content: { 'padding-left': '44px' }
}

class CampaignItemList extends Component {
  state = {
    open: false,
    launches: []
  };

  getObservations = (launch, campaign) => {
    return new Promise((resolve, reject) => {
      const url = launch ? `/observations/launch/${launch.id}?filter=${JSON.stringify({where:{record:0},order: "timestamp ASC"})}`: `/observations/campaign/${campaign.id}?filter=${JSON.stringify({where:{record:0},order: "timestamp ASC"})}`;
      dataFetch('GET', url)
        .then(data => resolve(data))
        .catch(err => reject(err));
    });
  };

  getOverlays = campaign => {
    return new Promise((resolve, reject) => {
      dataFetch('GET', `/campaigns/${campaign.id}/overlays`)
        .then(data => resolve(data))
        .catch(_ => resolve());
    });
  };

  getModelProperties = (datasetIds) => {
    return new Promise((resolve, reject) => {
      const filter = {
        include: 'equipment',
        where: {id: {inq: datasetIds}}
      };

      dataFetch('GET', `/datasets?filter=${JSON.stringify(filter)}`)
        .then(data => {
          if (data) {
            const equipmentModelIds = [...new Set(data.map(item => item.equipment.equipmentmodelId))];
            const filter = JSON.stringify({
              include: 'observedproperty',
              where: {equipmentmodelId: {inq: equipmentModelIds}}
            });
            dataFetch('GET', `/modelproperties?filter=${filter}`)
              .then((result) => {
                let datasets = {};
                data.forEach(dataset => {
                  datasets[dataset.id] = result.filter(item => {
                    if (item.equipmentmodelId === dataset.equipment.equipmentmodelId)
                      return item;
                    else
                      return null;
                  })
                });
                resolve(datasets);
              }).catch(err => reject(err));
          } else {
            resolve();
          }
        });
    });
  }

  getData = (campaign, launch = null) => {
    const { fetchStart, fetchEnd, fetchObservations, onClose, selectCampaign, storeOverlays, showNotification } = this.props;
    const promises = [
      this.getOverlays(campaign),
      this.getObservations(launch, campaign)
    ];
  
    fetchStart();
    Promise.all(promises)
      .then(data => {
        const overlays = data[0];
        const observations = data[1];
        // Get unique datasetIds
        const datasetIds = [...new Set(observations.map(item => item.datasetId))];
        this.getModelProperties(datasetIds)
          .then(data => {
            fetchObservations({
              observations: observations,
              observedProperties: data
            });
            storeOverlays(overlays);

            observations.length === 0 && showNotification('containers.maps.history.info.no_data')
          });
  
      })
      .finally(_ => {
        fetchEnd();
      });

      selectCampaign(campaign);
      onClose();
  };

  handleCollapseListItemClick = (launch, campaign) => {
    this.getData(campaign, launch);
  };
  
  handleAllDataClick = campaign => {
    this.getData(campaign);
  }

  handleListItemClick = value => {
    this.setState({'open': !this.state.open});
    const {fetchStart, fetchEnd} = this.props;

    if(this.state.launches.length === 0){
      fetchStart();
      dataProvider(GET_LIST, 'launches', {
        filter: { campaignId: value.id },
        sort: { field: 'beginTimestamp', order: 'ASC' },
        pagination: { page: 1, perPage: 0 },
      }).then(response => {
        return response.data;
      }).then(launches => {
        fetchEnd();
        this.setState({'launches': launches})
      });
    }
  };
  
  render() {
    const { open, launches } = this.state;
    const { campaign, classes, translate } = this.props;

    return (
      <div>
        <ListItem button onClick={() => this.handleListItemClick(campaign)}>
          <ListItemText 
            primary={campaign.name}
            secondary={<DateField source="beginDate" format='dd/MM/yyyy' record={campaign} simpleText noFields/>}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open}>
          {launches.map(launch => (
            <ListItem button className={classes.content} key={`${campaign.id}-${launch.id}`} onClick={() => this.handleCollapseListItemClick(launch, campaign)}>
              <ListItemText 
                primary={launch.name}
                secondary={
                  <span>
                    {translate('resources.launches.fields.beginTimestamp')}: <DateField source="beginTimestamp" format='dd/MM/yyyy HH:mm' record={launch} simpleText /> 
                    {/* <DateField className={classes.date} source="beginTimestamp" record={launch} showTime />  */}
                    {launch.endTimestamp ? ` | ${translate('resources.launches.fields.endTimestamp')}: ` : null}
                    <DateField source="endTimestamp" format='dd/MM/yyyy HH:mm' record={launch} simpleText /> 
                    {/* <DateField className={classes.date} source="endTimestamp" record={launch} showTime /> */}
                  </span>
                }
              />
            </ListItem>
          ))}
          {launches.length > 0 && <ListItem
            button
            divider
            className={classes.content}
            onClick={()=>this.handleAllDataClick( campaign )}
          >
            <ListItemText
              primary={translate('containers.maps.history.all_data')}
              secondary={translate('containers.maps.history.complete_interval')}
            />
          </ListItem>}
        </Collapse>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchObservations: data => dispatch(fetchObservationsAction(data)),
  storeOverlays: overlays => dispatch(storeOverlaysAction(overlays)),
  fetchEnd: () => dispatch(fetchEnd()),
  fetchStart: () => dispatch(fetchStart()),
  selectCampaign: (campaign) => dispatch(selectCampaignAction(campaign)),
  showNotification: error => dispatch(showNotification(error))
});

const mapStateToProps = state => ({
  observations: state.fetchedObservations
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate,
  withStyles(styles)
);

export default enhance(CampaignItemList);