import React, { Component } from 'react';
import {
  TextField,
} from 'react-admin';
import { Datagrid } from '../../components/CustomDatagrid';

class ListFileValues extends Component {

  constructor(props) {
    super(props);

    this.state = {
      choices: {},
      ids:[0],
      headers:[]
    };
  }

  componentDidMount(){
    const { values } = this.props.record;
    const headers = [];

    if(values.error) {
      headers.push({field: "error", type: "text"});
    }
    else {
      Object.keys(values).forEach((key) => {
        if(!isNaN(values[key])) {
          values[key] = parseFloat(values[key]).toFixed(3);
          headers.push({field: key, type: "number"});
        }
        else {
          headers.push({field: key, type: "text"});
        }
      });
    }

    this.setState({choices: {0: values}, headers});
  }

  render(){
    const { choices, ids, headers } = this.state;
    return (
      <div>
        <Datagrid choices={choices} ids={ids} >
          {headers.map(({field, type}) => <TextField key={field} source={field} sortable={false} />)}
        </Datagrid>
        <br />
      </div>
    )
  };
}

export default ListFileValues;