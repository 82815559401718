import React, { Component } from 'react';
import { connect } from 'react-redux';
import { URL_CATALOGUE } from '../../Configuration';
import compose from 'recompose/compose';

import {
  Button,
  fetchStart,
  fetchEnd,
  showNotification,
  translate
} from 'react-admin';

import {
  Link as LinkIcon,
  Publish as PublishIcon,
  LibraryBooks as CatalogueIcon
} from '@material-ui/icons';
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import dataFetch from '../../providers/dataFetch';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

class PublishButton extends Component {
  state = {
    link: null,
    label: 'pos.general.publish',
    button: <PublishIcon />,
    published: false,
    publishId: undefined,
    // datasetId,
    choices: [
      'catalogue',
      're_publish',
      'delete',
    ],
    open: false,
    anchorEl: null
  }

  deleteState = () => {
    this.setState({
      link: null,
      label: 'pos.general.publish',
      button: <PublishIcon />,
      published: false,
      publishId: undefined,
    });
  }


  componentDidMount() {
    const { record, basePath } = this.props;

    const base = {}

    if (basePath === 'derivedsets')
      base.derivedsetId = record.id;
    else
      base.datasetId = record.id;

    const filter = {
      where: {
        and: [
          { ...base },
          { externalLink: { neq: null } }
        ]
      },
      order: ['id DESC']
    };
    dataFetch('GET', `/publishes?filter=${JSON.stringify(filter)}`)
      .then(result => {
        if (result.length > 0) {
          this.updateState(result[0]);
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  updateState = (result) => {
    this.setState({
      link: `${URL_CATALOGUE}${result.externalLink}`,
      label: 'pos.general.catalogue',
      button: <LinkIcon />,
      published: true,
      publishId: result.id
    });
  }

  handlePublish = (event) => {
    const { record, fetchStart, fetchEnd, showNotification, basePath } = this.props;
    const { link, publishId } = this.state;

    this.setState({ anchorEl: null, open: false });

    if (event === 'publish' || event === 're_publish') {
      fetchStart();
      dataFetch('POST', `/${basePath}/${record.id}/publish`)
        .then(result => {
          this.updateState(result);
          showNotification('pos.general.publish_success', 'info')
        }).catch(_ => {
          showNotification('pos.general.publish_failed', 'warning');
        }).finally(_ => fetchEnd());
    }
    else if (event === 'catalogue') {
      window.open(link, '_blank');
    }
    else if (event === 'delete') {
      fetchStart();
      dataFetch('DELETE', `/publishes/${publishId}/catalogue`)
        .then(result => {
          this.deleteState();
          showNotification('pos.general.delete_success', 'info')
        }).catch(_ => {
          showNotification('pos.general.delete_failed', 'warning');
        }).finally(_ => fetchEnd());
    }
  }

  handleClickListItem = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  render() {
    const { published, anchorEl, open, choices } = this.state;
    const { translate, record, disabled = false } = this.props;

    return <>
      {record && record.numberObservations > 0 && record.status === 'UPDATED' && (published ?
        <>
          <Button
            label='pos.general.catalogue'
            onClick={this.handleClickListItem}
            disabled={disabled}
          >
            <CatalogueIcon />
          </Button>
          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={this.handleClose}
          >
            {choices.map((option, index) => (
              <MenuItem
                key={option}
                onClick={() => this.handlePublish(option)}
              >
                {translate('pos.general.' + option)}
              </MenuItem>
            ))}
          </Menu>
        </>
        :
        <Button
          label={this.state.label}
          onClick={() => this.handlePublish('publish')}
          disabled={disabled}
        >
          {this.state.button}
        </Button>
      )}
    </>
  }
}


export default compose(
  translate,
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
  })
)(PublishButton);