import React, { Component } from 'react';
import { Control } from './';

class Parameters extends Component {
  render() {
    const { map, recordId } = this.props
    return (
      <div>
        <Control map={map} recordId={recordId} />
      </div>
    );
  }
}

export default Parameters;