import React, { Component } from 'react';
import {
  Button,
  Filter,
  SearchInput,
  translate,
  showNotification,
} from 'react-admin';
import PublishButton from './PublishButton';
import {
  List as Icon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { stringify } from 'query-string';

import {
  ResponsiveL,
} from '../../components';

// import { SOCKET_DEBUG } from '../../Configuration';

// import { 
//   emit,
//   listen,
//   stopListen,
// } from '../../sockets'

import { compose } from 'recompose';
import { connect } from 'react-redux';

import ListDatasets from './ListDatasets';
// import { refreshView } from 'ra-core';

export const DatasetIcon = Icon;

const DatasetFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

const TableButton = props => (
  <Button component={Link}
    label='pos.general.table'
    to={{
      pathname: `/${props.reference}`,
      search: stringify({
        page: 1,
        perPage: 25,
        filter: JSON.stringify({ [props.src]: get(props.record, 'id') }),
      }),
      state: {
        dataset: props.record
      }
    }}
  >
    <DatasetIcon />
  </Button>
);


class _DatasetList extends Component {
  // componentDidMount() {
  //   this.props.emit('enter', {room : 'datasetList'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });

  //   this.props.listen('datasetList', (data) => { // ! duplicaded
  //     const { refresh, dataset } = data;
  //     if(refresh && this.props.ids.includes(dataset.id)) {
  //       // this.props.showNotification('sockets.update_data');
  //       this.props.refreshView();
  //     }
  //   })
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });
  // }

  // componentWillUnmount() {
  //   this.props.stopListen('datasetList')
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });

  //   this.props.emit('leave', {room : 'datasetList'})
  //   .catch(err => {
  //     if(SOCKET_DEBUG)
  //       console.log("err datasetList", err);
  //   });
  // }

  render() {
    const { classes, listen, stopListen, emit, showNotification, /*refreshView,*/ ...rest } = this.props;
    return (
      <ResponsiveL
        {...rest}
        listFilters={<DatasetFilter />}
        recordActions={[<TableButton src='datasetId' reference='observations' />, /*<MapButton />, <ChartsButton />,*/ <PublishButton />]}
      >
        <ListDatasets />
      </ResponsiveL >

    )
  }
}

const mapStateToProps = (state) => ({
  ids: state.admin.resources.datasets.list.ids
});

const enhance = compose(
  connect(mapStateToProps, {
    // listen,
    // stopListen,
    // emit,
    showNotification,
    // refreshView,
  })
);

export const DatasetList = enhance(_DatasetList);