import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import lodashSet from 'lodash/set';

import FilterFormInput from './MyFilterFormInput';

const styles = theme => createStyles({
  form: {
    marginTop: '-10px',
    paddingTop: 0,
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  body: { display: 'flex', alignItems: 'flex-end' },
  spacer: { width: '1em' },
  icon: { color: theme.palette.primary1Color || '#00bcd4', paddingBottom: 0 },
  clearFix: { clear: 'right' },
});

const sanitizeRestProps = ({
  anyTouched,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dispatch,
  displayedFilters,
  filterValues,
  handleSubmit,
  hideFilter,
  initialize,
  initialized,
  initialValues,
  invalid,
  pristine,
  pure,
  reset,
  resetSection,
  save,
  setFilter,
  setFilters,
  submit,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  triggerSubmit,
  untouch,
  valid,
  validate,
  ...props
}) => props;

export class FilterForm extends Component {
  componentDidMount() {
    this.props.filters.forEach(filter => {
      if (filter.props.alwaysOn && filter.props.defaultValue) {
        throw new Error(
          'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
        );
      }
    });
  }

  getShownFilters() {
    const { filters, displayedFilters, initialValues } = this.props;

    return filters.filter(
      filterElement =>
        filterElement.props.alwaysOn ||
        displayedFilters[filterElement.props.source] ||
        typeof initialValues[filterElement.props.source] !== 'undefined'
    );
  }

  handleHide = event =>
    this.props.hideFilter(event.currentTarget.dataset.key);

  render() {
    const { classes = {}, className, resource, initialValues, ...rest } = this.props;
    return (
      <div
        className={classnames(className, classes.form)}
        siga={initialValues}

        {...sanitizeRestProps(rest)}
      >
        {this.getShownFilters().map(filterElement => (
          <FilterFormInput
            key={filterElement.props.source}
            filterElement={filterElement}
            handleHide={this.handleHide}
            classes={classes}
            resource={resource}

          />
        ))}
        <div className={classes.clearFix} />
      </div>
    );
  }
}

FilterForm.propTypes = {
  resource: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.node).isRequired,
  displayedFilters: PropTypes.object.isRequired,
  hideFilter: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export const mergeInitialValuesWithDefaultValues = ({
  initialValues,
  filters,
  choices,
  ...props
}) => ({
  initialValues: {
    ...filters
      .filter(
        filterElement =>
          filterElement.props.alwaysOn &&
          filterElement.props.defaultValue
      )
      .reduce(
        (acc, filterElement) =>
          lodashSet(
            { ...acc },
            filterElement.props.source,
            filterElement.props.defaultValue
          ),
        {}
      ),
    ...initialValues,
  }
});

const enhance = compose(
  withStyles(styles),
  withProps(mergeInitialValuesWithDefaultValues),
  reduxForm({
    form: 'filterForm',
    enableReinitialize: true,
    destroyOnUnmount: false, // do not destroy to preserve state across navigation
    onChange: (values, dispatch, props) => {
      if (values && values.derivedsetId && !values.derivedsetId.inq) {
        const ids = props.choices.filter(_ => _.id === values.derivedsetId);
        values.derivedsetId = { inq: ids[0].derivedsets };
        delete values.id;
      }
      return props && props.setFilters(values)
    },
  })
);

export default enhance(FilterForm);