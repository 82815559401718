
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server'
import {
  ImageOverlay,
  Map,
  ScaleControl,
  TileLayer,
  WMSTileLayer
} from 'react-leaflet';
// Add link to the Leaflet Stylesheet in index.html to see MobMeasure Markers
// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { KEY_BING, KEY_GOOGLE, URL } from '../../Configuration';
import { BingLayer } from 'react-leaflet-bing';
import { GoogleLayer } from 'react-leaflet-google';

import MobMeasureControl from 'leaflet-mobmeasure';
import PathLayer from './PathLayer';
import CenterControl from './CenterControl';
import LocationControl from './LocationControl';
import { AnnotationControl } from '../annotations';
import AnnotationsDialog from '../../resources/derivedDatasets/annotations/DialogFromMap';
import { Parameters } from '../parameters';
import { NoteControl } from '../notes';
import MeasureIcon from '@material-ui/icons/Straighten';
import ContextLayersControl from './ContextLayersControl';
import { storage } from 'react-admin-loopback';
import Graticule from './Graticule';


import KMLControl from '../../plugins/react-leaflet-kml';

const { BaseLayer, Overlay } = ContextLayersControl;

class LeafletMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      map: null,
      // openAnnotationDialogByTag: false,
      tagAnnotationDialog: undefined,
      annotationIdDialog: undefined
    };

    this.openPopupMarker = this.openPopupMarker.bind(this);
    this.resetAnnotationTag = this.resetAnnotationTag.bind(this);
    this.setAnnotationTag = this.setAnnotationTag.bind(this);
    this.openAnnotation = this.openAnnotation.bind(this);
    this.closeAnnotation = this.closeAnnotation.bind(this);
  }
  
  resetAnnotationTag = () => {
    this.setState({tagAnnotationDialog: undefined})
  }
  
  setAnnotationTag = (tagId) => {
    this.setState({tagAnnotationDialog: tagId})
  }
  
  openAnnotation = (id) => {
    this.setState({annotationIdDialog: id})
  }
  
  closeAnnotation = () => {
    this.setState({annotationIdDialog: undefined})
  }
  
  componentDidMount() {
    this.setState({ map: this.refs.map.leafletElement });
  }

  openPopupMarker(wavy) {
    const { map } = this.state;
    if(map)
      map.eachLayer((layer) => {
        if(layer instanceof L.Marker && layer.options && layer.options.id === wavy)
          layer.openPopup();
      })
  }

  render () {
    const { children, currentLocation, annotation, parameters, note, refreshData, recordId, viewport, overlays, campaignOverlays = [] } = this.props;
    const { map, tagAnnotationDialog, annotationIdDialog } = this.state;

    const mobmeasureOptions = {
      lineColor: 'red',
      
      formatOutput: (distance, azimuth) => {
        let result = distance.toFixed(2) + ' m<br>' + (distance / 1852).toFixed(2) + ' M <br>' + azimuth.toFixed(2) + 'º'
        
        return result;
      },
      innerHtml: ReactDOMServer.renderToStaticMarkup(<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
        <MeasureIcon style={{
          transform: 'rotate(-45deg)',
          fontSize: '14px'
        }}/>
      </div>)
    };

    return (
      <Map ref='map' style={{ flex: 'auto' }} viewport={viewport}>
        <ContextLayersControl setAnnotationTag={this.setAnnotationTag} position="topright" sendCtrlLayer={(layer)=>this.props.sendCtrlLayer && this.props.sendCtrlLayer(layer)}>
          <BaseLayer name='Google Maps Roads'>
            <GoogleLayer googlekey={KEY_GOOGLE} maptype={'ROADMAP'} maxNativeZoom={19} maxZoom={23} minZoom={3} />
          </BaseLayer>
          <BaseLayer name="Bing (Hybrid)">
            <BingLayer bingkey={KEY_BING} type="AerialWithLabels" maxNativeZoom={19} maxZoom={23} minZoom={3} />
          </BaseLayer>
          <BaseLayer name="Open Street Map" opacity={1} checked>
            <TileLayer
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxNativeZoom={19}
              maxZoom={23}
              minZoom={3}
            />
          </BaseLayer>
                 

          <Overlay checked={true} name='Grid'>
            <Graticule checked={true} name='Grid' />
          </Overlay>

          {overlays ? overlays.map((layer) => {
            return  <Overlay name={layer.wavy.name} key={`overlay-${layer.wavy.id}`} checked>
                      <PathLayer layer={layer} />
                    </Overlay>
          }): null}

          {
            campaignOverlays.map((campaignOverlay)=> (
              campaignOverlay.remote ? (
                (campaignOverlay.overlayType === "wms" &&
                <Overlay name={campaignOverlay.name} key={campaignOverlay.id} checked>
                  <WMSTileLayer
                    url={campaignOverlay.filePath}
                    layers={campaignOverlay.layers}
                    format="image/png"
                    transparent={true}
                    opacity={campaignOverlay.opacity && parseFloat(campaignOverlay.opacity)}
                  />
                </Overlay>) ||
                (campaignOverlay.overlayType === "image" &&
                  <Overlay name={campaignOverlay.name} key={campaignOverlay.id} checked>
                    <ImageOverlay 
                      url={campaignOverlay.filePath} 
                      bounds={[
                        [campaignOverlay.northEast.lat, campaignOverlay.northEast.lng], 
                        [campaignOverlay.southWest.lat, campaignOverlay.southWest.lng]
                      ]}
                      opacity={campaignOverlay.opacity && parseFloat(campaignOverlay.opacity)}
                    />
                  </Overlay>) ||
                (campaignOverlay.overlayType === "kml" &&
                <Overlay name={campaignOverlay.name} key={campaignOverlay.id} checked>
                  <KMLControl data={campaignOverlay.filePath} />
                </Overlay>)
              ) :
              (campaignOverlay.overlayType.includes('image')?
                <Overlay name={campaignOverlay.name} key={campaignOverlay.id} checked>
                  <ImageOverlay 
                    url={`${URL}${campaignOverlay.filePath}?access_token=${storage.load('lbtoken').id}`} 
                    bounds={[
                      [campaignOverlay.northEast.lat, campaignOverlay.northEast.lng], 
                      [campaignOverlay.southWest.lat, campaignOverlay.southWest.lng]
                    ]}
                    opacity={campaignOverlay.opacity && parseFloat(campaignOverlay.opacity)}
                  />
                </Overlay>
                : 
                <Overlay name={campaignOverlay.name} key={campaignOverlay.id} checked>
                  <KMLControl data={`${URL}${campaignOverlay.filePath}?access_token=${storage.load('lbtoken').id}`}/>
                </Overlay>
              )
            ))
          }
        </ContextLayersControl>
        <ScaleControl />
        <CenterControl map={map} />
        {currentLocation && <LocationControl map={map} />}
        { annotation && <AnnotationControl map={map} recordId={recordId} refreshData={refreshData}/> }
        { annotation && <AnnotationsDialog annotationIdDialog={annotationIdDialog} tagAnnotationDialog={tagAnnotationDialog} modalClose={this.closeAnnotation} recordId={recordId}/> }
        { note && <NoteControl /> }
        { parameters && <Parameters map={map} recordId={recordId}/> }
        <MobMeasureControl {...mobmeasureOptions} />
        {children && React.Children.map(children, child => (
          child ? React.cloneElement(child, {
            openPopupMarker: this.openPopupMarker,
            openAnnotation: annotation && this.openAnnotation
          }) : null
        ))}
      </Map>
    );
  }
}


LeafletMap.defaultProps = {
  currentLocation: false,
  annotation: false
};

export default LeafletMap;