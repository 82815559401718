import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Create,
  // DateField,
  Pagination,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  fetchEnd,
  fetchStart,
  required,
  Filter,
  SearchInput,
  translate,
  BulkActionsToolbar,
  SimpleShowLayout,
  showNotification
} from 'react-admin';

import {
  List as TableIcon,
  Map as MapIcon,
  ShowChart as ChartIcon,
  // Update as UpdateIcon,
  AssistantPhoto as Icon,
  Edit as EditIcon,
} from '@material-ui/icons';

import {
  Typography,
  /*Card, */
  List,
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core"
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import {
  DefaultCardActions,
  ReferenceManyManyField,
  ResponsiveDatagrid,
  ResponsiveList,
  Edit,
  DateField,
  DownloadButton
} from '../../components';
import { API_URL } from '../../Configuration';

import ToolbarC from './DeleteContentToolbar';
import Status from './Status';

import MultipleDeleteDerivesetObs from './MultipleDeleteDerivesetObs'

import compose from 'recompose/compose';
import AddAnnotationActionButton from '../annotations/AddAnnotationActionButton';

import AnnotationButton from './annotations/Button';
import PublishButton from '../datasets/PublishButton';

import { dataFetch } from '../../providers';

import TagsField from './TagsField';
export const DerivedDatasetIcon = Icon;

const listStyles_DerivedDtsShow = createStyles({
  card: {
    position: 'relative',
    padding: '16px'
  },
  content2: {
    padding: '0px',
    // background: "red"
  }
});

const listStyles_DerivedDatagrid = createStyles({
  DerivedDatagrid: {
    flex: '1 1 auto',
    padding: '0px'
  }
});

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

const MapButton = ({ record }) => (
  // record && record.numberObservations > 0 && record.status === 'UPDATED' &&
  <Button component={Link} disabled={!(record && record.equipmentId)} label='pos.general.map' to={`/derivedsets/${record.id}/map`}><MapIcon /></Button>
)

const ChartButton = ({ record }) => (
  // record && record.numberObservations > 0 && record.status === 'UPDATED' &&
  <Button component={Link} disabled={!(record && record.equipmentId)} label='pos.general.chart' to={`/derivedsets/${record.id}/charts`}><ChartIcon /></Button>
)

// class UpdateButton extends Component {
//   state = { disabled: false };
//   render() {
//     return (
//       <Button disabled={this.state.disabled} label='pos.general.update' onClick={() => {
//         this.setState({ disabled: true });
//         dataFetch('POST', `/derivedsets/metadata/update/${this.props.record.id}`)
//           .then(result => {
//             this.props.showNotification('resources.derivedsets.notification.update_metadata')
//           })
//           .catch(error => {
//             this.props.showNotification('errors.SOMETHING', 'warning')
//           })
//           .finally(() => { this.setState({ disabled: false }) })

//       }}><UpdateIcon /></Button>
//     )
//   }
// }

const TableButton = ({ record }) => (
  // record && record.numberObservations > 0 && record.status === 'UPDATED' &&
  <Button component={Link} label='pos.general.table' to={`/derivedsets/${record.id}/show`}><TableIcon /></Button>
)

// const EditButton = ({record}) => (
//   <Button component={Link} label='pos.general.edit' to={`/derivedsets/${record.id}/edit`}><EditIcon/></Button>
// )

const DerivedFilter = translate(({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn label={translate('pos.search')} />
  </Filter>
));

export const _DerivedDtsList = ({ showNotification, ...props }) => (
  <ResponsiveList {...props}
    listFilters={<DerivedFilter />}
    recordActions={[<TableButton />, <MapButton />, <ChartButton />, <PublishButton basePath="derivedsets" />]}>
    <TextField source='name' />
    <TextField source='description' />
    <TextField source='numberObservations' />
    <Status source="status" />
  </ResponsiveList>
)

export const DerivedDtsList = compose(
  connect(null, { showNotification })
)(_DerivedDtsList);


export const DerivedDtsCreate = ({ ...props }) => (
  <Create actions={<DefaultCardActions />} {...props}>
    <SimpleForm redirect="/derivedsets">
      <TextInput source='name' validate={required()} />
      <TextInput source='description' validate={required()} />
    </SimpleForm>
  </Create>
)

export const DerivedDtsEdit = ({ ...props }) => (
  <Edit actions={<DefaultCardActions />} {...props}>
    <SimpleForm toolbar={<ToolbarC derived_id={props.id} />}>
      <TextInput source='name' validate={required()} />
      <TextInput source='description' validate={required()} />
    </SimpleForm>
  </Edit>
)


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class _DerivedActions extends Component {

  // const DerivedActions = ({ data, id, hasCreate, translate, ...props }) => {

  constructor(props) {
    super(props);

    this.state = {
      curated: undefined,
      downloadEnabled: false,
      tags: [],
    }
  }
  componentDidMount() {
    const { id } = this.props;

    const filter = {
      include: [
        {
          relation: 'equipment',
        }
      ]
    };

    fetchStart();
    dataFetch('GET', `/derivedsets/${id}?filter=${JSON.stringify(filter)}`)
      .then(data => this.setState({curated: data}))
      .finally(_ => fetchEnd());
  }
  filename = () => {
    return `WOS_CURATED_${this.state.curated?.equipment?.serialNumber}_${this.props.data?.name.replace(/ /g, '').toLocaleLowerCase()}_${this.props.id}`;
  }

  componentDidUpdate(oldProps) {
    const { data } = this.props;
    if (this.state.downloadEnabled === false && data && data.name && data.numberObservations > 0 && (data.status === 'UPDATED' || data.status === 'OUTDATED')) {
      this.setState({ downloadEnabled: true });
    } else if (this.state.downloadEnabled === true  && data && data.name && !data.numberObservations) {
      this.setState({ downloadEnabled: false });
    }
  }

  render() {
    const { id, data, translate, inserted_annotations, theme, classes, tags_selected, handleTagsChange, tags } = this.props;
    return (<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Typography variant='title'>{data && data.name}</Typography>
      <span style={{ display: 'flex' }}>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-multiple">{translate('resources.derivedsets.forms.buttons.tags_filter')}</InputLabel> 
          <Select
            multiple
            value={tags_selected}
            onChange={handleTagsChange}
            input={<Input id="select-multiple" />}
            MenuProps={MenuProps}
          >
            {tags.map(({name, id}) => (
              <MenuItem
                key={id}
                value={id}
                style={{
                  fontWeight:
                    tags_selected.indexOf(id) === -1
                      ? theme.typography.fontWeightRegular
                      : theme.typography.fontWeightMedium,
                }}
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button component={Link} disabled={!(data && data.equipmentId)} label='pos.general.chart' to={id ? `/derivedsets/${id}/charts` : '/derivedsets'}><ChartIcon /></Button>
        <Button component={Link} disabled={!(data && data.equipmentId)} label='pos.general.map' to={id ? `/derivedsets/${id}/map` : '/derivedsets'}><MapIcon /></Button>
        <AnnotationButton recordId={id} inserted_annotations={inserted_annotations} />

        <DownloadButton disabled={!this.state.downloadEnabled} filePath={`${API_URL}/containers/derivedsets/download/curated_${id}.csv`} fileName={`${this.filename()}.csv`} label={translate('resources.observations.button.csv_export')} />
        <DownloadButton disabled={!this.state.downloadEnabled} filePath={`${API_URL}/containers/derivedsets/download/curated_${id}.json`} fileName={`${this.filename()}.json`} label={translate('resources.observations.button.metadata_export')} />

        <Button component={Link} label='pos.general.edit' to={id ? `/derivedsets/${id}/edit` : `/derivedsets`}><EditIcon /></Button>
        <Button component={Link} label='pos.general.list' to={`/derivedsets`}><TableIcon /></Button>
      </span>
    </div>
    )
  }
}

const DerivedActions = withStyles(styles, { withTheme: true })(_DerivedActions)

const prop_order = [
  'record',
  'latitude',
  'longitude',
  'satellites',
  'nb_gnss_sat',
  'height',
  'payload',
  'speed',
  'course',
  'validity',
  'hdop',
  'vdop',
  'pdop',
  'sep',
  'sampleimu',
  'temperature',
  'temp_imu',
  'sampletemperature',
  'samplepressure',
  'temp_1',
  'temp_2',
  'temp_3',
  'temp_4',
  'atm',
  'Hm0',
  'Tm01',
  'Tm02',
  'Tp',
  'Mdir',
  'PkDir',
  'DirSpr',
  'PkDirSpr',
  'WmaxH',
  'bat',
  'rssi'
];

const remove_props = [
  "gpstime",
  "timestamp",
  "derivedsetId",
  "datasetId",
  "id",
  "position",
  "serialNumber",
  "properties",
  "references",
  "year",
  "second",
  "day",
  "hour",
  "minute",
  "month",
  "tags"
];

class DerivedDatagrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIds: [],
      version: 0,
      default_props: [],
      allData: {},
    }
    this.selectOne = this.selectOne.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  componentDidMount() {
    this.setState({ to_mount: true });
  }

  componentDidUpdate(OldProps) {
    if (OldProps.data !== this.props.data || this.state.to_mount) {
      this.setState({ to_mount: false });

      const { data, ids } = this.props;
      let { allData } = this.state;
      let obs = [];

      Object.keys(data).map(single => {

        if (!allData[single])
          allData[single] = data[single];

        if (ids.includes(single))
          obs = [...new Set([...Object.keys(data[single]), ...obs])];
        return {};
      });

      obs = obs.filter((prop) => {
        return remove_props.includes(prop) ? false : true;
      });

      let default_props = prop_order.filter((p) => {
        const index = obs.indexOf(p);
        if (index !== -1) {
          obs.splice(index, 1);
          return true;
        }
        return false;
      });

      this.setState({ default_props: [...default_props, ...obs], allData: allData, version: this.state.version + 1 });
    }
  }

  selectOne(e) {
    let { selectedIds } = this.state;
    const index = selectedIds.indexOf(e);
    if (index === -1)
      selectedIds.push(e);
    else
      selectedIds.splice(index, 1);

    this.setState({ selectedIds: selectedIds, version: this.state.version + 1 })
  }

  selectAll(e) {
    if (e.length === 0) {
      this.setState((state) => ({ selectedIds: state.selectedIds.filter(id => !this.props.ids.includes(id)), version: state.version + 1 }))
    } else {
      this.setState((state) => ({ selectedIds: [...e], version: state.version + 1 }))
    }
  }

  render() {
    const { fetchStart, fetchEnd, classes, className, insertedAnnotations, tags, ...props } = this.props;
    const { version, selectedIds, default_props } = this.state;
  return (
      <div>
        <BulkActionsToolbar selectedIds={selectedIds}>
          <MultipleDeleteDerivesetObs url={`/derivedsets/${props.parentrecord.id}${props.basePath}/rel`} allData={this.state.allData} derivedsetId={props.parentrecord.id} />
          <AddAnnotationActionButton reference='annotations' {...props} allData={this.state.allData} insertedAnnotations={this.props.insertedAnnotations} />
        </BulkActionsToolbar>

        <List>
          <ResponsiveDatagrid version={version} hasBulkActions={true} selectedIds={selectedIds} onToggleItem={this.selectOne} onSelect={this.selectAll} {...props}>
            <DateField key='datetime' source='timestamp' format='dd/MM/yyyy HH:mm:ss' noFields />
            {
              default_props.length > 0 && default_props.map((property, index) => {
                return <TextField key={index} source={property} sortable={false} />
              })
            }
            {tags.length > 0 && <TagsField key='tags' source='tags' tags={tags}/>}
          </ResponsiveDatagrid>
        </List>
      </div>
    )
  }
}

const ConnectedDerivedDatagrid = compose(
  withStyles(listStyles_DerivedDatagrid),
  connect(null, {
    fetchEnd,
    fetchStart
  }))(DerivedDatagrid)


class DerivedDtsShow_tmp extends Component {

  state = {
    inserted_annotations: 0,
    tags_selected: [],
    tags: [],
    filter_tag: undefined
  };

  insertedAnnotations = () => {
    this.setState({ inserted_annotations: this.state.inserted_annotations + 1 });
  }

  handleTagsChange = event => {
    const or = [];
    event.target.value.forEach((id) => {
      or.push({tags: {eq_regex: `\\|${id}-`}})
    });

    this.setState({ tags_selected: event.target.value, filter_tag: or.length > 0 ? {or} : undefined });
    this.forceUpdate();
  };

  componentDidMount() {
    fetchStart();
    dataFetch('GET', `/tags`)
      .then(data => this.setState({ tags: data }))
      .finally(_ => fetchEnd());
  }

  render() {
    const { classes, ...rest } = this.props;

    return <Show classes={{ card: classes.card }} actions={<DerivedActions inserted_annotations={this.state.inserted_annotations} tags_selected={this.state.tags_selected} tags={this.state.tags} handleTagsChange={this.handleTagsChange} {...rest} />} {...rest}>
      <SimpleShowLayout className={classes.content2} >
        <ReferenceManyManyField 
          source='derivedsets' 
          recordId={this.props.id} 
          reference='observations' 
          addLabel={false} 
          sort={{ field: 'timestamp', order: 'ASC' }} 
          pagination={<Pagination />} 
          filter={this.state.filter_tag}
          keepUpdating
        >
          <ConnectedDerivedDatagrid insertedAnnotations={this.insertedAnnotations} tags={this.state.tags}/>
        </ReferenceManyManyField>
      </SimpleShowLayout>
    </Show>
  }

}

export const DerivedDtsShow = compose(withStyles(listStyles_DerivedDtsShow), translate)(DerivedDtsShow_tmp) 