import React, { Component } from 'react';
import {
  TextField,
  DateField, 
} from 'react-admin';

import withStyles from '@material-ui/core/styles/withStyles';

import { Datagrid } from '../../components/CustomDatagrid';
import ListFileValues from './ListFileValues';

const styles = {

};

class ListFiles extends Component {

  constructor(props) {
    super(props);

    this.state = {
      choices: {},
      ids: []
    };
  }

  componentDidMount(){
    const { result } = this.props.record;
    const choices = {}, ids = [];
    if(result.length > 0) {
      result.forEach((value, index) => {
        choices[index] = value;
        ids.push(index);
      });

      this.setState({choices, ids});
    }
  }

  render(){
    const { choices, ids } = this.state;
    return (
      <Datagrid choices={choices} ids={ids} expand={<ListFileValues  newrow="true" />} >
        <DateField source='beginTime' showTime />
        <DateField source="endTime" showTime />
        <TextField source="observationsNumber" />
      </Datagrid>
    )
  }
}

export default withStyles(styles)(ListFiles);