import React from 'react';
import { connect } from 'react-redux';
import { Layout, Sidebar, defaultTheme } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';
import Menu from './Menu';

const styles = {
  appFrame: {
    flex: '1',
  }
};

export const getTheme = (type) => {
  const defaultThemeCopy = Object.assign({}, defaultTheme)
  defaultThemeCopy.palette = {
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    }
  };
  defaultThemeCopy.palette.type = type
  return defaultThemeCopy;
}

const CustomSidebar = props => <Sidebar size={200} {...props} />;
const CustomLayout = withStyles(styles)(({ classes, dispatch, ...props }) => (
  <Layout 
    {...props}  
    appBar={AppBar} 
    sidebar={CustomSidebar} 
    classes={classes}
    menu={Menu}
  />
));

export default connect(
  state => ({
    theme: getTheme(state.theme)
  })
)(CustomLayout);