import React, { Component } from 'react';
import { reset as resetFormAction } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {
  LongTextInput,
  NumberInput,
  fetchEnd,
  fetchStart,
  SimpleForm,
  showNotification,
  translate,
  // SelectInput
  // required
} from 'react-admin';
import {
  Dialog, IconButton,
  DialogTitle,
  DialogContent,
  withStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons'
import PropTypes from 'prop-types';
import ShowTime from '../annotations/ShowTime';
import { dataFetch } from '../../providers';

const styles = {
  content: { padding: 0 },
  titleDiv: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeButton: {
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  TextField: {
    maxWidth: "80%",
    display: 'flex',
    margin: '0 auto'
  },

  times: {
    paddingLeft: "24px",
    paddingRight: "24px"
  }
};

class Details extends Component {

  // constructor(props) {
  //   super(props);

  //   // this.state = {
  //   //   limit_form: "0"
  //   // };
  // }

  handleSave = (record) => {

    const { beginTime, endTime, recordId, showNotification, onClose } = this.props
    // const { limit_form } = this.state;

    const headers = new Headers({
      Accept: 'application/json',
      "Content-Type": "application/json"
    });


    const filter = {
      beginTime: beginTime, //new Date(beginTime),
      endTime: endTime, //new Date(endTime)
      derivedsetId: recordId,
      description: record.description,
      limit: 4096
    }

    const method = 'POST', url = '/parameters';

    // if(limit_form === "1" && record.observationsNumber) {
    //   filter.limit = record.observationsNumber;
    //   filter.period = null;
    // }
    // else if(limit_form === "2" && record.minutes) {
    //   filter.period = record.minutes;
    //   filter.limit = null;
    // }


    dataFetch(method, url, headers, JSON.stringify(filter))
      .then(result => {
        // fechar modal
        onClose();
        showNotification('containers.parameters.success');
        // this.props.reset('parameters');
      })
      .catch(err => { 
        if(err && err.error && err.error.code === "INSUFICIENT_DATA"){
          onClose(2);
          showNotification('resources.parameters.error', 'warning');
        } else if(err && err.error && err.error.message){
          console.error(err.error.message);
          onClose(3);
          showNotification("errors.SOMETHING", 'warning');
        } else{
          onClose(3);
          showNotification("errors.SOMETHING", 'warning');
        }
      });
  }

  // handleChange = (event) => {
  //   this.setState({limit_form: event['0']});
  // };

  componentDidMount() {
    this.props.reset('parameters');
  }

  render() {
    const { beginTime, endTime, translate, onClose, fullWidth, open, classes, description } = this.props
    return (<Dialog open={open} onClose={onClose} fullWidth={fullWidth}>
      <span className={classes.titleDiv}>
        <DialogTitle>
          {translate('containers.parameters.modalTitle')}
        </DialogTitle>

        <IconButton classes={{ root: classes.closeButton }} onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </span>
      <DialogContent onClick={this.handleCLick}>
        <div className={classes.times}>
          <p> {ShowTime(translate('containers.parameters.begin'), beginTime)} </p>
          <p> {ShowTime(translate('containers.parameters.end'), endTime)} </p>
        </div>
        <SimpleForm form='parameters' save={this.handleSave} resource={'parameters'}>
          <LongTextInput source='description' defaultValue={description || ""}/>
          {/* <SelectInput source="limit_form" onChange={this.handleChange} choices={[
              { id: "0", name: '' },
              { id: "1", name: translate('containers.parameters.observationsNumber')},
              { id: "2", name: translate('containers.parameters.timeLimit') },
          ]} defaultValue={"0"}/> */}
          {/* {
            this.state.limit_form === "1" &&  */}
          <NumberInput source='observationsNumber' defaultValue={4096} disabled />
          {/* }
          {
            this.state.limit_form === "2" && 
            <NumberInput source='minutes' defaultValue={20} />
          } */}
        </SimpleForm>
      </DialogContent>
    </Dialog>
    )
  }
}

Details.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    fetchStart,
    fetchEnd,
    showNotification,
    reset: resetFormAction,

  })
);

export default enhance(Details);