import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import compose from 'recompose/compose';
import TableCell from '@material-ui/core/TableCell';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { FieldTitle, translate } from 'ra-core';

// remove the sort icons when not active
const styles = createStyles({
  icon: {
    display: 'none',
  },
  active: {
    '& $icon': {
      display: 'inline',
    },
  },
});

export const DatagridHeaderCell = ({
  classes,
  className,
  field,
  resource,
  translate,
  ...rest
}) => (
  <TableCell
    className={classnames(className, field.props.headerClassName)}
    numeric={field.props.textAlign === 'right'}
    padding="none"
    variant="head"
    {...rest}
  >
    <FieldTitle
      label={field.props.label}
      source={field.props.source}
      resource={resource}
    />
  </TableCell>
);

DatagridHeaderCell.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  field: PropTypes.element,
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
};

const enhance = compose(
  translate,
  withStyles(styles)
);

export default enhance(DatagridHeaderCell);