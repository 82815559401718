import React, { Component } from 'react';
import {
  Datagrid,
  Responsive
} from 'react-admin';
import { MobileDatagrid } from '../index';

export default class ResponsiveDatagrid extends Component {

  render(){
    const { actions, children, ...rest } = this.props;
    const countOfChildren = React.Children.count(children);
    return <Responsive {...rest}
      small={
        <MobileDatagrid actions={actions && actions.map((action,index) => React.cloneElement(action, {key: index, /*hasIcon: true,*/ ...action.props}))} >
          {React.Children.map(children, (child, index)=> child && React.cloneElement(child,{key: index}))}
        </MobileDatagrid>
      }
      medium={
        <Datagrid>
          {React.Children.map(children, (child, index)=> child && React.cloneElement(child,{key: index}))}
          {actions && actions.map((action,index) => React.cloneElement(action, {key: countOfChildren + index, source:""}))}
        </Datagrid>
      }
    />
  }
}