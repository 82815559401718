import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  translate,
  showNotification
} from 'react-admin';
import compose from 'recompose/compose';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { 
  Details, 
  DetailsShow
} from '../annotations';
import { 
  Details as ParametersDetails, 
} from '../parameters';


import { connect } from 'react-redux';
// import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// am4core.useTheme(am4themes_animated);

am4core.options.minPolylineStep = 5;
am4core.options.queue = true;
am4core.options.onlyShowOnViewport = true;

const styles = {
  container:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '600px',
    width: '100%'
  }
}

class ReactChart extends Component {
  
  constructor(props){
    super(props);
    this.zoomChanged = this.zoomChanged.bind(this);
    this.state = {
      loaded: false,
      annotation: false,
      parameters: false,
      annotationDetail: false,
      annotationTimes:[],
      beginTime:undefined,
      endTime:undefined,
      beginTimeShowAnnotation:undefined,
      endTimeShowAnnotation:undefined,
      descriptionShowAnnotation:undefined,
      tagsShowAnnotation:undefined,
      allowAnnotations: undefined,
      allowParameters: undefined,
      annotationFirstClick: false,
    }
  }
  addAnnotationsHandler(annotationDatevalue){

    let times = this.state.annotationTimes;
    times.push(annotationDatevalue);

    if(times.length===2){
      let bT, eT;
      if(times[0] > times[1]){
        bT = times[1];
        eT = times[0];
      }else{
        bT = times[0];
        eT = times[1];
      }

      if(this.state.allowAnnotations)
        this.setState({ annotation: true, beginTime: bT, endTime: eT });
      else if(this.state.allowParameters)
        this.setState({ parameters: true, beginTime: bT, endTime: eT });

    }else{
      this.props.showNotification('pos.annotation.clicked');
      this.setState({annotationTimes: times});
    }
  }

  createAxisAndSeries(field, name, chart) {
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = field;
    series.dataFields.dateX = "timestamp";
   
    series.strokeWidth = 2;
    series.prop_name = name;
    series.hidden = true
    series.tooltipText = `${name}: [bold]{valueY}[/]`;
    series.legendSettings.valueText = name;
    series.legendSettings.itemValueText = name+" [bold]{valueY}[/]";

  }

  onClose = () => {
    this.setState({ annotation: false, parameters:false, annotationTimes: []});
  }
  onCloseAnnotationDetail = () => {
    this.setState({ annotationDetail: false});
  }
  success = () => {
    this.props.rerenderParentCallback();
    this.setState({ annotation: false, annotationTimes: []});
  }

  zoomChanged(ev) {
    if(this.state.loaded) {

      // let { minZoomed, maxZoomed } = ev.target; // timestamp
      let { minZoomed, maxZoomed } = this.chart.xAxes.values[0]; // timestamp // para ter acesso nos dois eventos
      const { data, series } = this.chart;

      const xError = (maxZoomed - minZoomed) * .1;
      minZoomed -= xError;
      maxZoomed += xError;
      
      let show_properties = []
      if(ev.type === "hit") {

        var seriesColumn = ev.target.dataItem.dataContext;
        seriesColumn.visible = !seriesColumn.visible

        if(!seriesColumn.visible)
          seriesColumn.hide()
        else 
          seriesColumn.show()
      }
      
      for(var i in series.values ) {
        if(series.values[i].visible)
          show_properties.push(series.values[i].prop_name)
      }      

      if(show_properties.length <= 0) // desnecessário continuar
        return;

      let map = data.filter(
          function(key, index, obj) { 

            if(key.timestamp < minZoomed && obj[index+1].timestamp > minZoomed)
              return true;
            if(index-1 > 0 && obj[index-1].timestamp < maxZoomed && key.timestamp > maxZoomed)
              return true;
            if(key.timestamp > minZoomed && key.timestamp < maxZoomed)
              return true; 
            return false;

        }).map(
          function(key) {
            let result = [];
            for( var i in show_properties ) {
              if(!isNaN(key[show_properties[i]]))
                result.push(parseFloat(key[show_properties[i]]))
              // else
              //   console.log("Error - Isn't a number!")
            }
            return result;
          });
     
      map = [].concat.apply([], map);
      let yMax = Math.max.apply(Math, map);
      let yMin = Math.min.apply(Math, map);

      const yError = (yMax - yMin) * .1;
      
      yMax += yError;
      yMin -= yError;

      this.chart.yAxes.values[0].min = yMin;
      this.chart.yAxes.values[0].max = yMax;
    }
  }

  annotationsHandler(annotationBar, annotation) {
    const barHighlightedOpacity = 1, barNotHighlightedOpacity = 0.3;
    const bulletHighlightedOpacity = 1, bulletNotHighlightedOpacity = 0.5;
    annotationBar.date = new Date (annotation.intervals[0].beginTime);
    annotationBar.endDate = new Date(annotation.intervals[0].endTime);
    annotationBar.grid.disabled = true;

    const colorNotDefined = 'blue';
    //if the annotation does not have a tag the bar will be blue
    if(annotation.tags[0] == null){
      annotationBar.axisFill.fill = colorNotDefined;
    } else {
      //if the annotation tag does not have a defined color, the color will also be blue
      if(!annotation.tags[0].color){
        annotationBar.axisFill.fill = colorNotDefined;
      } else {
        //if there is only one tag the bar will have the defined color
        if(annotation.tags.length === 1){
          annotationBar.axisFill.fill = annotation.tags[0].color;
        }
      }
    }
    
    //bullet is the triangle icon
    annotationBar.bullet = new am4core.Triangle();
    annotationBar.bullet.width = 15;
    annotationBar.bullet.height = 11;
    annotationBar.bullet.fill = am4core.color("#c00");
    annotationBar.bullet.horizontalCenter = "middle";
    annotationBar.bullet.dx = -7;//set the triangle position more to the left to be 100% centered
    let bulletText;
    if(annotation.text.length > 9){
      bulletText = annotation.text.substring(0,9)+'...';
    }else{
      bulletText = annotation.text;
    }
    annotationBar.bullet.tooltipText = bulletText;
    annotationBar.bullet.draggable = true;

    annotationBar.axisFill.fillOpacity = barNotHighlightedOpacity;
    annotationBar.bullet.fillOpacity = bulletNotHighlightedOpacity;

    annotationBar.bullet.events.on(
      "hit",
      ev => {
        const { tags } = annotation;
        // let tags;
        // if(annotation.tags[0] != null){
        //   tag = annotation.tags[0].name;
        // }else{
        //   tags = undefined;
        // }
        this.setState({annotationDetail: true, beginTimeShowAnnotation: annotationBar.date,
                      endTimeShowAnnotation: annotationBar.endDate,
                      descriptionShowAnnotation: annotation.text,
                      tagsShowAnnotation: tags});
      },
      this);
    annotationBar.bullet.events.on(
      "over",
      ev => {
        if(annotationBar.axisFill.fillOpacity !== barHighlightedOpacity){
          annotationBar.axisFill.fillOpacity = barHighlightedOpacity;
          annotationBar.bullet.fillOpacity = bulletHighlightedOpacity;
        }
      },
      this);
    annotationBar.bullet.events.on(
      "out",
      ev => {
        if(annotationBar.axisFill.fillOpacity !== barNotHighlightedOpacity){
          annotationBar.axisFill.fillOpacity = barNotHighlightedOpacity;
          annotationBar.bullet.fillOpacity = bulletNotHighlightedOpacity;
        }
      },
      this);

  }

  componentDidMount() {
    const { annotationsEnabled } = this.props;

    let chart = am4core.create("chartdiv", am4charts.XYChart);
    
    chart.colors.step = 2;
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.tooltipDateFormat = "hh:mm:ss, d-M-yyyy";
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.events.on("selectionextremeschanged", this.zoomChanged);
    dateAxis.renderer.labels.template.dy = 10;//put the labels a bit lower to give space for the triangles

    chart.yAxes.push(new am4charts.ValueAxis());
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.minHeight = 30;//sets the height of the scrollbar
    chart.cursor = new am4charts.XYCursor();

    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.paddingTop = 5;
    chart.legend.itemContainers.template.paddingLeft = 3;
    chart.legend.itemContainers.template.paddingRight = 3;
    chart.legend.itemContainers.template.paddingBottom = 5;
    chart.legend.itemContainers.template.togglable = false;
    chart.legend.itemContainers.template.events.on("hit", this.zoomChanged);

    if(annotationsEnabled === true){
      //add event to listen for any click in chart
      chart.plotContainer.events.on("hit",ev => {this.setState({clickOnChart: true})}, this);

      //add event to listen for any change of position in cursor
      //and for when the cursor is clicked
      chart.cursor.events.on('cursorpositionchanged', ev => {
        //if the user selected to add Annotations
        if(this.state.allowAnnotations || this.state.allowParameters){

          //if a click on the chart has been made
          if(this.state.clickOnChart === true){

            const xValue = ev.target._upPointOrig.x;
            const yValue = ev.target._upPointOrig.y;
            const xMaxValue = ev.target.maxRight;
            const yMaxValue = ev.target.maxBottom;

            if((xValue > 0 & xValue < xMaxValue) & (yValue > 0 & yValue < yMaxValue)){
              const xAxis = ev.target.chart.xAxes.getIndex(0);          
              let annotationDate = new Date(xAxis.positionToDate(xAxis.toAxisPosition(ev.target.xPosition))).getTime();
              this.addAnnotationsHandler(annotationDate);

              if(this.state.annotationFirstClick === true){
                this.setState({annotationFirstClick: false});
              }else{
                this.setState({annotationFirstClick: true});
              }
            }
            this.setState({clickOnChart: false});
          }
        }else{
          this.setState({clickOnChart: false});
        }
      });
    }
    this.chart = chart;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
      this.chart = null
    }
  }

  componentDidUpdate(oldProps) {
    const { data, properties, annotations, allowToAddAnnotations, allowToAddParameters, annotationsEnabled, parametersEnabled } = this.props

    if(annotationsEnabled === true){
      if(this.state.allowAnnotations!== allowToAddAnnotations){
        this.setState({allowAnnotations: allowToAddAnnotations});
      }  
    }
    if(parametersEnabled === true){
      if(this.state.allowParameters!== allowToAddParameters){
        this.setState({allowParameters: allowToAddParameters});
      }  
    }
    //check the state of the toggle from the parent

    if (data != null && oldProps.data.length === 0 && data.length !== 0) {
      // i = 0 -> timestamp
      for( var i = 1; i < properties.length; i++ ) {
        const name = properties[i];
        // TRANSLATE -> SECOND ARG
        this.createAxisAndSeries(name, name, this.chart);
      }
      this.chart.data = data;
      this.setState({ loaded: true });
      let dateAxis = this.chart.xAxes._values[0];
      dateAxis.tooltipDateFormat = "hh:mm:ss, d-M-yyyy";

      if(annotationsEnabled === true){
        annotations.forEach(annotation => {
          var annotationBar = dateAxis.axisRanges.create();
          this.annotationsHandler(annotationBar, annotation);
         });
      }

      //this.chart.validateData()
    }else{
      if(annotationsEnabled === true){
        //if an annotation was added
        if(annotations != null && annotations.length > oldProps.annotations.length){
          let dateAxis = this.chart.xAxes._values[0];
          const annotation = annotations[annotations.length-1];  
          const annotationBar = dateAxis.axisRanges.create();
          this.annotationsHandler(annotationBar, annotation);
        } 
      }
    }
  }


  render() {
    const { classes, recordId, annotationsEnabled, parametersEnabled /*, translate */} = this.props;
    return <div >
      <div id="chartdiv" style={{height:'68vh'}} className={ classes.container }></div> 
        {annotationsEnabled && <Details open={this.state.annotation} onClose={this.onClose} beginTime={this.state.beginTime} endTime={this.state.endTime} recordId={recordId} success={this.success}/>}
        {parametersEnabled && <ParametersDetails open={this.state.parameters} onClose={this.onClose} beginTime={this.state.beginTime} endTime={this.state.endTime} recordId={recordId} success={this.success}/>}
        {annotationsEnabled && <DetailsShow open={this.state.annotationDetail} onClose={this.onCloseAnnotationDetail} 
        beginTime={this.state.beginTimeShowAnnotation} endTime={this.state.endTimeShowAnnotation} 
        description={this.state.descriptionShowAnnotation} tags={this.state.tagsShowAnnotation}/>}
      </div>
  }
}

ReactChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  properties: PropTypes.arrayOf(PropTypes.string)
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    showNotification,
  })
);

export default enhance(ReactChart);