import React from 'react';
import { UserMenu, MenuItemLink, translate } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileIcon from '@material-ui/icons/Person';
import ManualIcon from '@material-ui/icons/School';
import ServiceDeskIcon from '@material-ui/icons/HelpOutline';

import { MenuItemExternalLink } from './components';

const CustomUserMenu = translate(({ translate, ...props }) => (
  <UserMenu {...props}>
    <MenuItemLink
      to="/profile"
      primaryText={translate('pos.profile.profile')}
      leftIcon={<ProfileIcon />}
    />
    <MenuItemLink
      to="/configuration"
      primaryText={translate('pos.configuration')}
      leftIcon={<SettingsIcon />}
    />
    <MenuItemLink
      to="/manual"
      primaryText={translate('pos.manual')}
      leftIcon={<ManualIcon/>}
    />
    <MenuItemExternalLink
      to="https://servicedesk.ec-meloa.eu/servicedesk/servicedesk/customer/portal/3"
      primaryText={translate('pos.servicedesk')}
      leftIcon={<ServiceDeskIcon/>}
    />
  </UserMenu>
));

export default CustomUserMenu;
