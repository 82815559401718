import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { translate } from 'react-admin';

const styles = {
  media: {
      height: '18em',
  },
};

const mediaUrl = `https://www.ec-meloa.eu/img/banner/banner_3_imagem_1520698050.png`;

const Welcome = ({ classes, translate }) => (
  <Card>
    <CardMedia image={mediaUrl} className={classes.media} />
    <CardContent>
      <Typography variant="headline" component="h2">
        {translate('pos.dashboard.welcome.title')}
      </Typography>
      <Typography component="p">
          {translate('pos.dashboard.welcome.subtitle')}
      </Typography>
    </CardContent>
  </Card>
);

export default withStyles(styles)(translate(Welcome));