import React, { Fragment, Component } from 'react';
import {
  GET_LIST,
  Button,
  // DateField,
  ListButton,
  TextField,
  Show,
  SimpleShowLayout,
  crudGetOne as crudGetOneAction,
  fetchEnd,
  fetchStart,
  setListSelectedIds as setListSelectedIdsAction,
  showNotification,
  required,
  SimpleForm,
  TextInput,
  translate,
} from 'react-admin';
import { Typography, withStyles } from '@material-ui/core';
import {
  Map as MapIcon,
  ShowChart as ChartIcon,
  Add as CopyDataset,
} from '@material-ui/icons'
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// import AddAnnotationActionButton from '../annotations/AddAnnotationActionButton';
import AddDerivedObsButton from '../derivedDatasets/AddDerivedObservationButton';
import {
  ReferenceManyManyField,
  Accordion,
  LinkButtonToResource as BtnToAnnotation,
  DefaultCardActions,
  ResponsiveList,
  SimpleModal,
  DateField,
  DownloadButton
} from '../../components';
import { dataProvider } from '../../providers'
// import { datasetExporter } from '../../utils/util';


import dataFetch from '../../providers/dataFetch';
import { push } from 'react-router-redux';
// import fetchFile from '../../providers/fetchFile'
import { API_URL } from '../../Configuration';

const listStyles = {};

const ObservationsBulkActions = props => (
  <Fragment>
    {/* <AddAnnotationActionButton reference='annotations' {...props}/> */}
    <AddDerivedObsButton {...props} />
  </Fragment>
);

class _ObservationsActions extends Component {
  state = {
    isOpen: false,
    saving: false,
    downloadEnabled: false
  }

  openDialog = () => this.setState({ isOpen: true })

  closeDialog = () => this.setState({ isOpen: false })

  copy_dataset = ({ name, description }) => {

    if (!this.state.saving) {
      const { id } = this.props.record;

      const headers = new Headers({
        Accept: 'application/json',
        "Content-Type": "application/json"
      });

      this.props.fetchStart();
      this.setState({ saving: true });
      dataFetch('POST', `/derivedsets/dataset/${id}/copy`, headers, JSON.stringify({ name: name, description: description }))
        .then(_ => {
          this.props.push(`/derivedsets`)
          this.props.showNotification("resources.datasets.copyNotification");
        })
        .catch(_ => console.error(_))
        .finally(_ => this.props.fetchEnd());
    }
    else {
      this.props.showNotification('Saving');
    }
  }

  filename = () => {
    const { record } = this.props;
    let name = 'WOS_';

    name += record.equipment ? `${record.equipment.serialNumber}_` : '';
    name += record.launch && record.launch.campaign ? `${record.launch.campaign.name.replace(/ /g, '').toLocaleLowerCase()}_` : '';
    name += record.launch ? `${record.launch.name.replace(/ /g, '').toLocaleLowerCase()}_` : '';

    name += name === 'WOS_' ? `${record.name}_${record.id}` : `${record.id}`;

    return name;
  }

  componentDidUpdate(oldProps) {
    const { record } = this.props;
    if(this.state.downloadEnabled === false && record && record.name && (record.status === 'UPDATED' || record.status === 'OUTDATED')) {
      this.setState({downloadEnabled: true});
    }
  }

  render() {
    const { record, translate } = this.props;
    return <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
      <Typography variant='title'>{record && record.name}</Typography>
      <span style={{ display: 'flex' }}>
        <Button component={Link} label='pos.general.chart' to={record ? `/datasets/${record.id}/charts` : '/datasets'}><ChartIcon /></Button>
        <Button component={Link} label='pos.general.map' to={record ? `/datasets/${record.id}/map` : '/datasets'}><MapIcon /></Button>

        <Button onClick={this.openDialog} label={translate('resources.datasets.copyButton')}><CopyDataset /></Button>
        <SimpleModal open={this.state.isOpen} onClose={this.closeDialog} title={translate('resources.datasets.createDerivedset')} >
          <SimpleForm redirect="/derivedsets" save={this.copy_dataset}>
            <TextInput source='name' validate={required()} />
            <TextInput source='description' validate={required()} />
          </SimpleForm>
        </SimpleModal>

        <ListButton label='Datasets' basePath={'datasets'} />
        <DownloadButton disabled={!this.state.downloadEnabled} filePath={`${API_URL}/containers/datasets/download/dataset_${record.id}.csv`} fileName={`${this.filename()}.csv`} label={translate('resources.observations.button.csv_export')} />
        <DownloadButton disabled={!this.state.downloadEnabled} filePath={`${API_URL}/datasets/${record.id}/metadata`} fileName={`${this.filename()}.json`} label={translate('resources.observations.button.metadata_export')} />
      </span>
    </div>
  }
}

const ObservationsActions = compose(
  translate,
  connect(
    null,
    {
      push,
      showNotification,
      fetchEnd,
      fetchStart
    }
  )
)(_ObservationsActions)

const extractIdFromURL = url => {
  return url.match(/\d+(?=%7D)/g);
}

const prop_order = [
  'record',
  'latitude',
  'longitude',
  'satellites',
  'nb_gnss_sat',
  'height',
  'payload',
  'speed',
  'course',
  'validity',
  'hdop',
  'vdop',
  'pdop',
  'sep',
  'sampleimu',
  'temperature',
  'temp_imu',
  'sampletemperature',
  'samplepressure',
  'temp_1',
  'temp_2',
  'temp_3',
  'temp_4',
  'atm',
  'Hm0',
  'Tm01',
  'Tm02',
  'Tp',
  'Mdir',
  'PkDir',
  'DirSpr',
  'PkDirSpr',
  'WmaxH',
  'bat',
  'rssi'
];

const remove_props = [
  "gpstime",
  "timestamp",
  "derivedsetId",
  "datasetId",
  "id",
  "position",
  "serialNumber",
  "properties",
  "references",
  "year",
  "second",
  "day",
  "hour",
  "minute",
  "month"
];

class ObservationListController extends Component {
  state = {
    dataset: null,
    fetchObsProps: true,
    allData: {},
    default_props: [],
    // default_props_ori:["record", "latitude", "longitude", "height", "speed", "course", "satellites", "validity", "hdop", "vdop", "pdop", "sep", "ax", "ay", "az", "gx", "gy", "gz", "ma", "my", "mz", "temp_imu", "temp_1", "temp_2", "temp_3", "temp_4", "atm", "rssi"],
    // remove_props: ["gpstime", "timestamp", "derivedsetId", "datasetId", "id", "position", "serialNumber", "properties", "references", "year", "second", "day", "hour", "minute", "month"]
  }

  sanitizeProps = ({ has_data, crudGetOne, setListSelectedIds, fetchEnd, fetchStart, ...props }) => ({ ...props });

  componentDidUpdate(oldProps) {
    if (oldProps.all_data !== this.props.all_data && this.props.has_data) {
      const { all_data, list } = this.props;
      let { allData } = this.state;
      let obs = []

      Object.keys(all_data).map(single => {

        if (!allData[single])
          allData[single] = all_data[single];

        if (list.includes(single))
          obs = [...new Set([...Object.keys(all_data[single]), ...obs])]
        return {}
      });

      obs = obs.filter((prop) => {
        return remove_props.includes(prop) ? false : true;
      });

      let default_props = prop_order.filter((p) => {
        const index = obs.indexOf(p);
        if (index !== -1) {
          obs.splice(index, 1);
          return true;
        }
        return false;
      });


      this.setState({ default_props: [...default_props, ...obs], allData: allData })
    }
  }

  componentDidMount() {
    let { dataset } = this.state;

    if (!dataset) {
      const datasetId = parseInt(extractIdFromURL(this.props.location.search)[0]);

      dataset = {
        id: datasetId
      };

      this.setState({ dataset: dataset })

      const filter = {
        filter: { id: datasetId },
        include: [
          {
            relation: 'equipment',
          },
          {
            relation: "launch",
            scope: {
              include: {relation: "campaign"}
            }
          }
        ],
        sort: {
          field: 'id',
          order: 'ASC'
        },
        pagination: {
          page: 0,
          perPage: -1
        }
      }

      fetchStart();
      dataProvider(GET_LIST, 'datasets', filter)
        .then(resp => resp.data[0])
        .then(data => this.setState({ dataset: data }))
        .finally(_ => fetchEnd());
    }
  }

  componentWillUnmount() {
    this.props.setListSelectedIds('observations', []);
  }

  render() {
    const filter = { datasetId: this.state.dataset && this.state.dataset.id/*extractIdFromURL(this.props.location.search)[0]*/, _counts: "annotations" };
    const { default_props, allData } = this.state;

    return <ResponsiveList
      {...this.sanitizeProps(this.props)}
      bulkActionButtons={<ObservationsBulkActions all_data={allData} />}
      listActions={<ObservationsActions record={this.state.dataset} history={this.props.history} filter={filter} />}
      // exporter={datasetExporter(`dataset_${this.state.dataset && this.state.dataset.id}`)}
      recordActions={[<BtnToAnnotation source="annotation" sortable={false} field='annotationsCount' reference='annotations' hasIcon />]}
      filter={filter}
      sort={{ field: 'timestamp', order: 'ASC' }}
    >
      <DateField key='datetime' source='timestamp' format='dd/MM/yyyy HH:mm:ss' noFields />
      {
        this.props.has_data &&
        default_props.length > 0 && default_props.map((property, index) => {
          return <TextField key={index} source={property} sortable={false} />
        })
      }
    </ResponsiveList>
  }
}

const mapStateToProps = (state, props) => {
  const { data, list } = state.admin.resources.observations;

  // TODO: improve this, beacuse is a resource and maybe will disappear NOT THIS BUT ON UPDATE PROPS
  if (list.ids.length > 0) {
    return { all_data: data, has_data: true, list: list.ids }
  }
  return {}
}

const enhance = compose(
  withStyles(listStyles),
  connect(
    mapStateToProps,
    {
      crudGetOne: crudGetOneAction,
      fetchEnd,
      fetchStart,
      setListSelectedIds: setListSelectedIdsAction,
    }
  )
)

export const ObservationShow = props => {
  return <Show actions={<DefaultCardActions history={props.history} removeListButton={true} />} {...props} >
    <SimpleShowLayout >
      <ReferenceManyManyField source='observations' reference='annotations' addLabel={false}>
        <Accordion />
      </ReferenceManyManyField>
    </SimpleShowLayout>
  </Show>
}

export const ObservationList = enhance(ObservationListController)