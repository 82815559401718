import React, { Component } from 'react';
import {
  SearchInput,
  Show,
  ShowButton,
  SimpleShowLayout,
  TextField,
  translate,
  ReferenceField,
  // DateField,
  ReferenceFieldController,
  ShowController, 
  ShowView,
  Responsive
} from 'react-admin';
// import withStyles from '@material-ui/core/styles/withStyles';
import WavesIcon from './wavesIcon';
import {
  ResponsiveList,
  DateField
} from '../../components';

import { dataFetch } from '../../providers';
import SelectEquipments from './SelectEquipments'
import FilterDerivedByEquipments from './FilterDependencies/FilterDerivedByEquipments'
import Status from './Status'
import ListFiles from './ListFiles';

import SimpleShowLayoutColumn from './SimpleShowLayoutColumn';
import ShowActionsClass from './ShowActionsClass';


export const ParametersIcon = WavesIcon;

//if the parameter status is one of the status in the below array the parameters
//are not shown
const statusDontShow = ['PROCESSING', 'ERROR', 'CREATED', 'PENDING'];

class TextFieldLimitedSize extends Component {
  render(){
    const {record} = this.props;
    const maxLength = 30;
    if(record.description.length > maxLength){
      return (record.description.substring(0,maxLength)+'...');
    }else{
      return (record.description);
    }
  }
}

export const ParametersShow = (({ data, record, source, classes, ...props }) => {
  return (
    <Show actions ={null} title=" "  {...props} >
      <ShowController {...props}>
        {controllerProps =>
          <ShowView actions={<ShowActionsClass {...props} />} {...props} {...controllerProps}>
                <Responsive
                  small={
                  <SimpleShowLayout>
                    <Status source="status" />
                    {/* <DateField key='datetime' source="beginTime" showTime />
                    <DateField key='datetime' source="endTime" showTime /> */}
                    <DateField source="beginTime" format='dd/MM/yyyy HH:mm:ss' />
                    <DateField source="endTime" format='dd/MM/yyyy HH:mm:ss' />
                    <TextField source="description" />
                      {(controllerProps.record &&
                        statusDontShow.filter((status)=> status === controllerProps.record.status).length === 0) ?
                        [
                          <TextField key='processTime' source="processTime" />,
                          <TextField key='totalObservations' source="totalObservations" />,
                          <ListFiles key='listFiles' newrow="true" />
                        ] : null
                      }
                  </SimpleShowLayout>}
                  medium={
                  <SimpleShowLayoutColumn numberColumns={5}>

                    <Status source="status" />
                    {/* <DateField key='datetime' source="beginTime" showTime />
                    <DateField key='datetime' source="endTime" showTime /> */}
                    <DateField source="beginTime" format='dd/MM/yyyy HH:mm:ss' />
                    <DateField source="endTime" format='dd/MM/yyyy HH:mm:ss' />
                    <TextField newrow="true" source="description" />
                    {(controllerProps.record &&
                      statusDontShow.filter((status)=> status === controllerProps.record.status).length === 0) ?
                      [
                        <TextField key='processTime' source="processTime"/>,
                        <TextField key='totalObservations' source="totalObservations" />,
                        <ListFiles key='listFiles' newrow="true" />
                      ] : null
                    }
                  </SimpleShowLayoutColumn>}/>   
          </ShowView>
        }
      </ShowController>
    </Show>
  )
});

const ParametersFilter = translate(({ translate, choices, ...props }) => {
  return (
    <FilterDerivedByEquipments {...props} choices={choices}>
      <SelectEquipments label="resources.parameters.equipment" source="derivedsetId" choices={choices} alwaysOn />
      <SearchInput source="q" alwaysOn label={translate('pos.search')} />
    </FilterDerivedByEquipments>
  )
});

export class ParametersList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      choices: []
    }
  }

  componentDidMount() {
    const filter = {
      include: {
        relation: "derivedset",
        scope: {
          include: {
            relation: "equipment"
          }
        }
      }
    };
    let equipments = {};
    dataFetch('GET', `/parameters?filter=${JSON.stringify(filter)}`)
      .then(data => {
        data.forEach(parameter => {
          if (!equipments[parameter.derivedset.equipment.id]) {
            equipments[parameter.derivedset.equipment.id] = {
              serialNumber: parameter.derivedset.equipment.serialNumber,
              derivedsets: [parameter.derivedsetId]
            };
          }
          else if (!equipments[parameter.derivedset.equipment.id].derivedsets.includes(parameter.derivedsetId)) {
            equipments[parameter.derivedset.equipment.id].derivedsets.push(parameter.derivedsetId);
          }
        });

        const choices = Object.keys(equipments).map(id => {
          return {
            id: id,
            // serialNumber: equipments[id].serialNumber,
            name: equipments[id].serialNumber,
            derivedsets: equipments[id].derivedsets,
            key: id
          };
        })
        this.setState({ choices: choices })
      })
      .catch(err => console.log("Error", err));
  }

  render() {
    return (
      <ResponsiveList
        {...this.props}
        listFilters={<ParametersFilter choices={this.state.choices} />}
        recordActions={[<ShowButton />]}
      >
        <ReferenceFieldController label='resources.parameters.equipment' reference="derivedsets" source="derivedsetId" linkType={false} sortable={false} >
          {({ referenceRecord }) => (
            <ReferenceField basePath="/derivedsets" resource="derivedsets" reference="equipment" source="equipmentId" record={referenceRecord || {}} linkType="show" allowEmpty>
              <TextField source="name" sortable={false} />
            </ReferenceField>
          )}
        </ReferenceFieldController>

        <ReferenceField label="resources.parameters.derived" source="derivedsetId" reference="derivedsets" linkType="show" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <TextFieldLimitedSize source="description" />
        <TextField key='totalobservations' source="totalObservations" />
        {/* <DateField key='datetime' source="beginTime" showTime />
        <DateField key='datetime' source="endTime" showTime /> */}
        <DateField source="beginTime" format='dd/MM/yyyy HH:mm:ss' />
        <DateField source="endTime" format='dd/MM/yyyy HH:mm:ss' />
        <Status source="status" />

      </ResponsiveList>
    )
  }
}
